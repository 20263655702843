/**
 * Created by BETALOS on 20/06/2017.
 */
(function () {

    'use strict';

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: DialogController,
        template: require("../views/list.default.tpl.html"),
    };

    DialogController.$inject = ["$mdDialog", "listService"];

    function DialogController($mdDialog, listService) {
        let vm = this;

        vm.list = _.assign(vm.list, {model_name: vm.mnModel});

        vm.submit = submit;
        vm.cancel = $mdDialog.cancel;

        function submit() {
            listService.handleList(vm.list).then($mdDialog.hide);
        }

    }

})();