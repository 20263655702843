(function () {

    'use strict';

    module.exports = BiologyPrescriptionFormCtrl;

    BiologyPrescriptionFormCtrl.$inject = ["system", "prescriptionService", "$transition$", "$mdDialog", "$q", "$scope", "textEditorService", "$translate"];

    function BiologyPrescriptionFormCtrl(system, service, $transition$, $mdDialog, $q, $scope, textEditorService, $translate) {
        let vm = this;
        let dateFormat = system['date_format'].js;

        let currentPatient = _.get($transition$.params('to'), 'pId');
        let currentVisit = _.get($transition$.params('to'), 'visitId');
        let currentPrescription = _.get($transition$.params('to'), 'prescriptionId');
        let currentRequest = _.get($transition$.params('to'), 'requestId');
        let currentCopy = _.get($transition$.params('to'), 'copy');
        let legacy = _.get($transition$.params('to'), 'legacy', false) ? true : false;

        vm.editorConfigKey = "biology_prescription";
        vm.editorOpts = {};

        vm.commentField = {
            meta_data: {},
            label: $translate.instant('comment'),
        }

        vm.$onInit = init;
        vm.submit = submit;
        vm.saveModel = saveModel;
        vm.prePrint = prePrint;

        vm.cancel = goBack;
        vm.deleteItem = service.deleteItem;

        function init() {
            let promise = [];

            service.prescriptionType.next({type: 'BiologyPrescription'});
            service.itemActive.next({id: -1, type: null});
            service.itemDuplicated.next({id: -1, type: null});
            service.itemEdited.next({id: currentPrescription, type: 'BiologyPrescription'});

            vm.editorName = _.uniqueId("biology-prescription-");

            if (!_.isNull(currentCopy)) {
                service.itemDuplicated.next({id: currentCopy, type: 'BiologyPrescription'});
                promise.push(service.getBiologyPrescription(currentCopy))
            } else if (!_.isNull(currentPrescription)) {
                if (legacy) {
                    promise.push(service.getLegacyBiologyPrescription(currentPrescription));
                } else {
                    promise.push(service.getBiologyPrescription(currentPrescription, !_.isNull(currentRequest)));
                }
            }

            else if (!_.isNull(currentRequest)) promise.push(service.getBiologyPrescription(currentRequest));
            else promise.push(createEmptyBiologyPrescription());

            vm.patient = currentPatient;
            vm.isResult = false;
            vm.editModel = false;

            let itemDeletedSubscription = service.itemDeleted.subscribe(itemDeleted);

            function itemDeleted(item) {
                if (_.isEqual(item, {
                        id: currentPrescription,
                        prescription_type: 'BiologyPrescription'
                    })
                ) service.listState();
            }

            $scope.$on("$destroy", onDestroy);

            function onDestroy() {
                itemDeletedSubscription.unsubscribe();
            }

            vm.promise = $q.all(promise).then(success);

            function success(data) {
                let actualData = _.head(data);

                vm.prescription = !_.isNull(currentCopy) || (!_.isNull(currentRequest) && _.isNull(currentPrescription)) ? _.assign(_.pick(actualData, ['details', 'comment']), {
                    visit: {id: currentVisit},
                    prescription_date: moment().format(dateFormat)
                }) : actualData;

                if (_.isNull(currentCopy)) {
                    vm.editModel = actualData['is_model'];

                    service.prescriptionType.next({
                        type: 'BiologyPrescription',
                        model: vm.editModel
                    })
                }

                if (!_.isNull(currentRequest)) {
                    vm.isResult = true;
                    vm.prescription.request = {id: currentRequest};
                    service.getResult(currentRequest).then(resultCallback);
                }
            }

            function resultCallback(data) {
                if (_.isNil(data)) return;

                vm.prescription.details = _.reduce(vm.prescription.details, function (result, detail) {
                    if (!_.isUndefined(data[detail.exam.id])) {
                        if (detail.exam['is_unique']) detail.value = data[detail.exam.id];
                        else detail.value = _.merge(detail.value, data[detail.exam.id])
                    }

                    result.push(detail);

                    return result;
                }, []);
            }

            function createEmptyBiologyPrescription() {
                return {
                    prescription_date: moment().format(dateFormat),
                    details: [],
                    comment: "",
                    visit: {id: currentVisit}
                }
            }

            service.useBiologyModel.subscribe(useBiologyModel);

            function useBiologyModel(data) {
                if (vm.isResult) return;

                if (_.isObject(data) && !_.isUndefined(data.exam)) {
                    insertDetailFromModel(data);
                } else {
                    vm.promise = service.getBiologyModelPrescription(data)
                        .then(success);
                }

                function success(data) {
                    _.forEach(data.details, insertDetailFromModel);
                }
            }
        }

        function insertDetailFromModel(newDetail) {
            let detail = _.find(vm.prescription.details, {exam: {id: newDetail.exam.id}});

            if (_.isUndefined(detail)) {
                // exam not found in current prescription
                vm.prescription.details.push(newDetail);
            } else {
                // exam found
                detail.value = _.assign({}, detail.value, newDetail.value);
                detail.last_value = _.assign({}, detail.value, newDetail.value);
            }
        }

        function submit(quit) {
            /*let record = textEditorService.getEditor(vm.editorName).getContent();
            if (_.isNil(vm.prescription.record) || vm.prescription.record != record)
                vm.prescription.record = record;*/


            if (vm.editModel) return service.saveBiologyPrescription(vm.prescription, currentPatient).then(_.bind(modelSuccess, quit));
            else if (_.isNull(currentRequest)) return saveRequest(quit);
            else return saveResult(quit);
        }

        function saveResult(quit) {
            vm.prescription.details = _.reduce(vm.prescription.details, function (result, detail) {
                if (detail.exam['is_unique'] && !_.isNull(detail.value)) {
                    result.push(detail);
                }
                if (!detail.exam['is_unique']) {
                    _.forEach(detail.value, function (value, uid) {
                        if (_.isNull(value)) _.unset(detail.value, uid);
                    });

                    if (!_.isEmpty(detail.value)) result.push(detail);
                }
                return result;
            }, []);

            return service.saveBiologyPrescriptionResult(vm.prescription, currentPatient)
                .then(data => saveCallback(data, quit));
        }

        function saveRequest(quit) {
            return service.saveBiologyPrescriptionRequest(vm.prescription, currentPatient)
                .then(data => saveCallback(data, quit));
        }

        function saveCallback(data, quit) {
            if (vm.editModel) service.modelAdded.next(data);
            else service.itemAdded.next(data);

            if (quit === "list") return service.listState();
            if (quit === "back") return goBack();

            if (_.isNull(currentPrescription)) {
                vm.prescription = data;

                currentPrescription = data.id;
                service.refreshState(data);
            }
        }

        function saveModel($event) {
            let confirm = $mdDialog.mnPromptDialog()
                .title('exam_model_title')
                .event('prescription.BiologyPrescription.title_validation')
                .placeholder('title')
                .targetEvent($event)

            $mdDialog.show(confirm).then(success);

            function success(data) {
                let model = _.assign(_.pick(vm.prescription, ['details', 'comment']), {title: data});
                service.saveBiologyPrescription(model)
                    .then(modelSuccess);
            }
        }

        function prePrint() {
            let deferred = $q.defer();

            if (vm.isResult)
                textEditorService
                    .getEditor(vm.editorName)
                    .compileContent();

            submit()
                .then(function () {
                    deferred.resolve(false);
                });

            return deferred.promise;
        }

        function modelSuccess(data) {
            service.modelAdded.next(data);
            if (_.isBoolean(this) && this) return service.listState();
        }

        function goBack() {
            return window.history.back();
        }

    }

})();