(function () {

    'use strict';

    module.exports = {
        controller: MedicineFormCtrl,
        controllerAs: "vm",
        template: require("shared/views/medicine-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true
    };

    MedicineFormCtrl.$inject = ["authService", "$q", "$mdDialog", "medicineService", "prescriptionService"];

    function MedicineFormCtrl(authService, $q, $mdDialog, medicineService, prescriptionService) {
        let vm = this;
        let posologiesDialog = require("shared/dialogs/medicine-posologies-dialog");

        vm.$onInit = init;
        vm.cancel = $mdDialog.cancel;
        vm.submit = submit;
        vm.checkToggle = checkToggle;
        vm.managePosologies = managePosologies;
        vm.addNewPresentation = addNewPresentation;
        vm.removePresentation = removePresentation;

        function init() {
            let promises = [
                prescriptionService.getConfig()
            ];

            vm.is_root = authService.profile.is_root;

            if (!_.isNil(vm.copy)) {
                promises = _.concat(promises, getMedicineData(vm.copy, true));
            } else if (!_.isNil(vm.currentMedicine)) {
                promises = _.concat(promises, getMedicineData(vm.currentMedicine));
            }

            vm.promise = $q.all(promises).then(success);

            function success(data) {
                let configuration = data[0];
                let medicine = _.get(data, "1", {});

                vm.advanceForm = _.get(configuration, "advance_mp", false);
                vm.useArabForm = _.get(configuration, "arab_generator.is_used", false);

                vm.usePara = _.get(configuration, "use_para", false);

                medicine.commercial_name_ar = _.get(medicine, "ar_instance.commercial_name", "");
                medicine.default_prescription = _.get(data, "2", null);

                vm.isPara = medicine.is_para_product || false;

                if (vm.duplicate) {
                    vm.is_mn = false;
                    medicine = _.chain(medicine)
                        .cloneDeep()
                        .omit(['id', 'ar_instance.id', '_cls', 'ar_instance._cls'])
                        .set('is_mn', false)
                        .set('is_system', false)
                        .set('ar_instance.is_mn', false)
                        .set('ar_instance.is_system', false)
                        .value()

                }

                vm.medicine = _.cloneDeep(medicine);
            }
        }

        function getMedicineData(medicineId, duplicate = false) {
            vm.duplicate = duplicate;

            return [
                medicineService.getMedicine(medicineId, vm.is_mn),
                medicineService.getDefault({id: medicineId})
            ]
        }

        function submit() {
            if (vm.isPara) vm.medicine.is_para_product = true;

            medicineService.saveMedicine(vm.medicine).then(medicineAdded, _.noop);

            function medicineAdded(data) {
                let default_prescription = vm.medicine.default_prescription;
                vm.medicine = data;
                medicineService.saveDefault({
                    medicine: data,
                    text: _.get(default_prescription, "text", ""),
                    text_ar: _.get(default_prescription, "text_ar", "")
                }).then(defaultSaved, _.noop);
            }

            function defaultSaved() {
                $mdDialog.hide(vm.medicine);
            }
        }

        function checkToggle(attr) {
            let oldValue = _.get(vm.medicine, attr);

            _.set(vm.medicine, attr, !oldValue);
        }

        function managePosologies($event) {
            $mdDialog.show(_.assign({}, posologiesDialog, {
                targetEvent: $event,
                locals: {
                    medicine: vm.medicine
                }
            }))//.then(success);
        }

        function addNewPresentation() {
            if (!_.isArray(vm.medicine["packaging_presentations"])) vm.medicine["packaging_presentations"] = [];
            vm.medicine["packaging_presentations"].push({})
        }

        function removePresentation($index) {
            vm.medicine["packaging_presentations"].splice($index, 1);
        }

        /*function loadMedicine(ev) {
            $mdDialog.show(_.assignIn(require("shared/dialogs/medicine-search-dialog"), {
                targetEvent: ev,
                locals: {},
                multiple: true
            })).then(success);

            function success(data) {
                vm.medicine = data;
            }
        }*/

    }

})();