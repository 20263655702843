(function () {

    'use strict';

    class TableSearchBarCtrl {
        constructor() {
            this.key = '';
            this.itemsFunc = this.itemsFunc || null;
        }

        static get $inject() {
            return [];
        }

        $onInit() {
            this.initItems = this.initItems ? this.initItems : [];
        }

        $onChanges(changes) {
            if (changes.initItems) this.handleSearch();
        }

        clearSearch() {
            this.key = '';
            this.itemsFunc({$event: this.initItems});
        }

        handleSearch() {
            let items = []
            if (this.key.length == 0) items = this.initItems || [];
            else {
                let key = this.handleKey(this.key);
                items = _.reduce(this.initItems, (items, item) => {
                    let titles = this.handleItem(item);

                    if (_.isString(titles) && _.includes(titles, key)) return _.concat(items, item);
                    else if (_.chain(titles).filter(i => _.includes(i, key)).isEmpty().value()) return items;
                    else return _.concat(items, item);
                }, []);
            }

            this.itemsFunc({$event: items});
        }

        handleKey(key) {
            return _.chain(key).toLower().deburr().value();
        }

        handleItem(item) {
            if (_.isString(item)) return this.handleKey(item);
            else return _.chain(item).values().map(i => this.handleKey(i)).value();
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($element, $attrs) {
        $element.addClass('layout-row md-whiteframe-z1');

        return `
            <md-icon md-font-icon="mdi-magnify" md-font-set="mdi"></md-icon>
            <input type="text" class="flex" ng-model="vm.key" mn-auto-focus="vm.focus" translate-once-placeholder="search" 
                ng-model-options="{debounce : 400}" ng-change="vm.handleSearch()"> 
            <md-button class="md-icon-button" ng-click="vm.clearSearch()" aria-label="reset" ng-if="vm.key.length > 0">
                <md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>
            </md-button>
        `
    }

    module.exports = {
        bindings: {
            focus: "&",
            initItems: "<",
            itemsFunc: "&?items",
        },
        template: tpl, // or template
        controllerAs: "vm",
        controller: TableSearchBarCtrl,
    };

})();