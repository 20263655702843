/**
 * Created by amine on 10/03/2017.
 */
(function () {
    'use strict';

    module.exports = MainPaymentFormCtrl;

    MainPaymentFormCtrl.$inject = ["paymentService", "$translate", "system", "$state", "$stateParams", "$scope", "configService", "$q"];

    function MainPaymentFormCtrl(paymentService, $translate, system, $state, $stateParams, $scope, configService, $q) {
        let vm = this;
        const dateFormat = system['date_format'].js;

        vm.$onInit = init;

        vm.submit = submit;
        vm.cancel = goBack;

        vm.patientCallback = patientCallback;
        vm.removePatient = clear;
        vm.organizationCallback = organizationCallback;
        vm.getPayables = getPayables;
        vm.onReorder = onReorder;
        vm.onPaginate = onPaginate;
        vm.getData = getData;
        vm.showProcedures = showProcedures;
        vm.rowInputClick = rowInputClick;
        vm.rowInputChange = rowInputChange;
        vm.getTotal = getTotal;
        vm.validateSelectedVisits = validateSelectedVisits;
        vm.validatePayee = validatePayee;
        vm.payerChange = payerChange;
        vm.payeeChange = payeeChange;
        vm.clear = clear;
        vm.deleteEncasement = deleteEncasement;
        vm.dissociate = dissociate;
        vm.headInvalidate = headInvalidate;

        vm.upload = () => Promise.resolve(false);

        function init() {
            let encasementId = $stateParams["encasementId"];
            let patientId = $stateParams["patientId"];

            vm.skipChange = {
                payer: false,
                payee: false
            };
            vm.field = {
                label: $translate.instant('comment'),
            };
            vm.isDental = configService.isDental();
            vm.reset = _.isNil($stateParams["reset"]) ? 0 : $stateParams["reset"];
            vm.readOnly = !_.isNil(encasementId);
            vm.closed = false;
            vm.payeeReadOnly = !_.isNil(patientId);
            vm.invalidatedHeader = false;
            vm.amount_label = "payment_received_amount";
            vm.model = {
                payment_date: moment(),
                amount: 0,
                physician: {},
                payee: {},
                payee_type: "P",
                details: [],
                payer: {},
                payer_type: "P"
            };

            vm.paginationLabel = {
                page: $translate['instant']('page'),
                rowsPerPage: $translate['instant']('rowsPerPage'),
                of: $translate['instant']('of')
            };
            vm.selectedPayable = null;
            vm.payables = [];
            vm.total = 0;
            vm.query = {
                page: 1,
                limit: 5,
                order: '-date'
            };
            vm.options = [5, 10];

            if (!_.isNil(encasementId)) {
                if (vm.reset > 0) {
                    vm.invalidatedHeader = vm.reset === 2;
                    paymentService
                        .resetEncasement(encasementId)
                        .then(loadEncasement, paymentService["mainState"]);
                } else {
                    paymentService
                        .getEncasement(encasementId)
                        .then(loadEncasement, paymentService["mainState"]);
                }
            } else if (!_.isNil(patientId)) patientCallback({id: patientId});

            $scope.$watchCollection("vm.model.details", detailsChanged);

            function detailsChanged(newValue, oldValue) {
                if (_.isEmpty(newValue) && _.isEmpty(oldValue)) return;
                let selectedItem = _.differenceBy(newValue, oldValue, 'id');
                let is_selected = !_.isEmpty(selectedItem);
                if (!is_selected) selectedItem = _.differenceBy(oldValue, newValue, 'id');
                rowSelection(selectedItem, is_selected)
            }
        }

        function getPayables() {
            const order = vm.query.order[0] === '-' ? 'desc' : 'asc';
            const min = (vm.query.page - 1) * vm.query.limit, max = (vm.query.page) * vm.query.limit;

            return _.chain(vm.payables)
                .orderBy(payable => moment(payable.model.date, dateFormat).valueOf(), order)
                .slice(min, max)
                .value();
        }

        function rowSelection(payables, selected) {
            _.forEach(payables, selected ? select : deselect);

            function select(payable) {
                const amount = vm.model.amount - getTotal();

                if (amount > -1) {
                    if (amount - payable.model.remaining_amount > 0) {
                        payable.model.total_amount = payable.model.remaining_amount;
                    } else {
                        payable.model.total_amount = amount;
                    }
                } else {
                    payable.model.is_selected = false;
                    _.remove(vm.model.details, payable);
                }
            }

            function deselect(payable) {
                payable.model.is_selected = selected;
                payable.model.total_amount = 0;
            }
        }

        function payerChange() {
            if (vm.readOnly) return false;

            vm.model.payer = null;
            switch (vm.model.payer_type) {
                case "P":
                case "T":
                    vm.model.payer = _.cloneDeep(vm.model.payee);
                    break;
                case "O":
                    vm.model.payer = _.cloneDeep(paymentService["getCls"]("unregistered"));
                    break;
            }
        }

        function payeeChange() {
            if (vm.readOnly) return false;

            switch (vm.model.payee_type) {
                case "P":
                    vm.model.payer_type = "P";
                    break;
                case "T":
                    vm.model.payee = _.assign(vm.model.payee, paymentService["getCls"]("organization"));
                    vm.model.payer_type = "T";
                    break;
            }
        }

        function submit(exit = false) {
            let encasement = {};
            let deferred = $q.defer();
            // encasement = _.chain(encasement)
            //     .assign(vm.model)
            //     .pick(["id", "payer", "payee", "payment_mode", "total_amount", "physician"])
            //     .value();
            if (_.isEmpty(vm.model.payer)) payerChange();

            if (vm.reset || !vm.model.id) {
                encasement = _.chain(encasement)
                    .assign(vm.model)
                    .pick([
                        "id",
                        "payer",
                        "payee",
                        "payment_mode",
                        "total_amount",
                        "physician"
                    ])
                    .assign({
                        encasement_date: vm.model.payment_date,
                        total_amount: vm.model.amount
                    })
                    .value();
            } else {
                encasement = _.chain(encasement)
                    .assign(vm.model)
                    .pick(["id"])
                    .value();
            }


            if (_.isEmpty(vm.model.details)) {
                paymentService.saveEncasement(encasement)
                    .then(success, deferred.reject);
            } else {
                let payment = {
                    total_amount: vm.getTotal(),
                    payment_date: vm.model.payment_date,
                    encasement: encasement,
                    details: _.reduce(vm.model.details, prepareDetails, [])
                };

                paymentService.addPayment(payment)
                    .then(success, deferred.reject);
            }

            function prepareDetails(result, payable) {
                payable.financial_status.paid_amount += payable.model.total_amount;
                payable.financial_status.is_validated = true;
                payable.financial_status.validate_at = moment();

                if (vm.model.payee_type === "T")
                    payable.financial_status.organization_affectation.paid_amount += payable.model.total_amount;

                let detail = {
                    total_amount: payable.model.total_amount,
                    _cls: paymentService.getCls(payable.model.type)
                };

                switch (payable.model.type) {
                    case "visit":
                        detail.visit = payable;
                        break;
                    case "plan":
                        detail.treatment_plan = payable;
                        _.unset(detail.treatment_plan, 'global_discount');
                }

                return _.concat(result, detail);
            }

            function success(data) {
                vm.upload({encasement: _.isUndefined(data.encasement) ? data.id : data.encasement.id}).then(() => {
                    if (_.isUndefined(data.encasement)) afterValidation(data);
                    else {
                        paymentService.validateDeliveryDocuments(data.id)
                            .then(() => afterValidation(data.encasement), deferred.reject);
                    }
                });
            }

            function afterValidation(encasement) {
                if (exit) {
                    deferred.resolve(true);
                    goBack();
                } else {
                    paymentService
                        .encasementState(encasement)
                        .then($state.reload, deferred.reject)
                        .then(deferred.resolve, deferred.reject);
                }
            }

            return deferred.promise;
        }

        function goBack() {
            window.history.back();
        }

        function loadEncasement(data) {
            let amountToShow = 0;
            vm.closed = data.remaining_amount === 0;
            if (vm.closed) {
                amountToShow = data.total_amount;
            } else {
                vm.amount_label = "payment_outstanding_amount";
                amountToShow = data.remaining_amount;
            }
            vm.title = $translate.instant('open_encasement', {
                date: data.encasement_date,
                amount: data.total_amount
            });

            vm.model = _.assign({}, vm.model, {
                id: data.id,
                payment_date: vm.reset || vm.closed ? data.encasement_date : moment(),
                payment_mode: data.payment_mode,
                amount: amountToShow,
                physician: data.physician,
                payee: data.payee,
                payee_type: data.payee_type,
                payer: data.payer,
                payer_type: data.payer_type,
                details: [],
            });

            //console.log(_.cloneDeep(vm.model.payer_type));
            // if (data.payee_type == "P") patientCallback(data.payee);
            // else if (data.payee_type == "T") organizationCallback();
        }

        function deleteEncasement(ev) {
            paymentService.deleteEncasement(vm.model, ev)
                .then(paymentService['mainState'], paymentService['mainState'])
        }

        function dissociate() {
            paymentService.encasementState(vm.model, 1).then($state.reload);
        }

        function headInvalidate() {
            paymentService.encasementState(vm.model, 2).then($state.reload);
        }

        function clear() {
            vm.total = 0;
            vm.payablePromise = null;
            vm.payables = [];
            vm.model.payer = null;
            vm.model.payee = null;
        }

        function patientCallback(data = {}) {
            vm.model.payee = _.assign({}, paymentService["getCls"]("patient"), data);

            payeeChange();
            getData();
        }

        function organizationCallback() {
            payeeChange();
            getData();
        }

        function onReorder(order) {
            vm.query.order = order;
        }

        function onPaginate(page, limit) {
            vm.query = _.assign({}, vm.query, {
                page: page,
                limit: limit
            });
        }

        function getData() {
            if (!vm.isDental) getSimpleData();
            else getDentalData();
        }

        function getSimpleData() {
            vm.payablePromise = $q.all([getVisits()]).then(getDataSuccess, _.noop);
        }

        function getDentalData() {
            vm.payablePromise = $q.all([getVisits(), getDentalTreatmentPlan()]).then(getDataSuccess, _.noop);
        }

        function getVisits() {
            if (vm.model.payee_type === "P")
                return paymentService
                    .getVisits({patient: vm.model.payee.id, hide_paid: true, only_patient: true, all_items: true});

            if (vm.model.payee_type === "T")
                return paymentService
                    .getOrganizationVisits({
                        organization: vm.model.payee.id,
                        only_organization: true,
                        hide_paid: true,
                        only_patient: true,
                        all_items: true
                    });
        }

        function getDentalTreatmentPlan() {
            if (vm.model.payee_type === "P")
                return paymentService
                    .getDentalTreatmentPlan({
                        patient: vm.model.payee.id,
                        hide_paid: true,
                        only_patient: true,
                        all_items: true
                    });

            if (vm.model.payee_type === "T")
                return paymentService
                    .getOrganizationTreatmentPlan({
                        organization: vm.model.payee.id,
                        only_organization: true,
                        hide_paid: true,
                        only_patient: true,
                        all_items: true
                    });
        }

        function getDataSuccess(data) {
            vm.selectedPayable = null;
            vm.total = _.sumBy(data, 'length');
            vm.payableSummary = _.assign(vm.payableSummary, {
                gross_total: _.sumBy(data, 'gross_total'),
                paid_amount: _.sumBy(data, 'paid_amount'),
                remaining_amount: _.sumBy(data, 'remaining_amount')
            });

            vm.payables = _.reduce(data, function (result, item, index) {
                const preparedList = _.reduce(item.list, preparePayable(index), []);
                return _.concat(result, preparedList);
            }, []);

            if (vm.total < ((vm.query.page - 1) * vm.query.limit)) {
                vm.query.page = 1;
            }
        }

        function preparePayable(index) {
            const types = [{
                type: "visit",
                date: "visit_date",
                procedures: "procedures",
                uid: 1
            }, {
                type: "plan",
                date: "creation_date",
                procedures: "teeth_procedures",
                uid: -1
            }];

            return (result, value) => {
                let payable = _.find(vm.model.details, {id: value.id, type: value.type});
                let remaining_amount = value.financial_status['remaining_amount'];

                if (vm.model.payee_type === "P" && value.financial_status.organization_affectation) {
                    remaining_amount = value.financial_status.organization_affectation['patient_remaining_amount']
                } else if (vm.model.payee_type === "T") {
                    remaining_amount = value.financial_status.organization_affectation['remaining_amount']
                }

                if (_.isUndefined(payable)) {
                    value.model = {
                        uid: types[index].uid * value.id,
                        global_discount: value.financial_status.global_discount,
                        has_global_discount: value.financial_status.paid_amount > 0,
                        remaining_amount: remaining_amount,
                        total_amount: 0,
                        type: types[index].type,
                        date: _.get(value, types[index].date, '-'),
                        hasProcedures: _.get(value, types[index].procedures, []).length > 0
                    }
                } else {
                    value.model = {
                        is_selected: true,
                        global_discount: payable.model.global_discount,
                        has_global_discount: payable.financial_status.paid_amount > 0,
                        total_amount: payable.model.total_amount,
                    }
                }

                return _.concat(result, value);
            }
        }

        function showProcedures(payable, $event) {
            $event.stopPropagation();

            if (vm.selectedPayable === payable.id) {
                vm.selectedPayable = -1;
            } else {
                vm.selectedPayable = payable.id;
            }
        }

        function rowInputClick($event) {
            $event.stopPropagation();
        }

        function rowInputChange(payable) {
            // to fix
            if (payable.model.total_amount > 0 && !payable.model.is_selected) {
                payable.model.is_selected = true;
                vm.model.details.push(payable);
            } else if (payable.model.total_amount === 0) {
                payable.model.is_selected = true;
                _.remove(vm.model.details, _.pick(payable, "id"));
            }
        }

        function getTotal() {
            const total = _.reduce(vm.model.details, (result, value) => result + value.model.total_amount, 0);

            return _.isNaN(total) ? 0 : total;
        }

        function validateSelectedVisits() {
            return (vm.model.details.length > 0 && getTotal() > 0) || vm.model.details.length == 0;
        }

        function validatePayee() {
            return _.has(vm.model.payee, "id");
        }
    }

})();