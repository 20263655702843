(function () {

    'use strict';

    module.exports = {
        controller: DialogCtrl,
        controllerAs: "vm",
        template: require("../views/measurement-chart-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: true,
        bindToController: true,
        multiple: true,
    };

    var Chart = require('chart.js');

    DialogCtrl.$inject = ["$mdDialog", "measureService", "$element"];

    function DialogCtrl($mdDialog, measureService, $element) {
        var vm = this;

        vm.$onInit = init;
        vm.choose = choose;
        vm.cancel = $mdDialog.cancel;

        var context = $("canvas", $element).get(0).getContext("2d");

        var chart = new Chart(context, {
            type: 'line',
            data: {
                datasets: [],
                labels: []
            },
            options: {
                maintainAspectRatio: true
            }
        });

        var colors = [
            "#FF5722",
            "#43A047",
            "#03A9F4",
            "#009688",
            "#3F51B5",
            "#673AB7",
            "#F44336",
            "#9C27B0",
            "#E91E63",
        ]

        function init() {
            vm.promise = measureService.getMeasures()
                .then(success);

            function success(data) {
                vm.items = _.filter(data, function (o) {
                    return _.includes(['float', 'integer'], o.type);
                });
            }
        }

        function choose(item) {
            if (_.eq(vm.currentItem, item)) return;

            vm.currentItem = item;

            vm.promise = measureService.getMeasureChart(vm.patient, item)
                .then(success);

            function success(data) {
                var label = _.isNull(item.unit) ? item.name : item.name + ' (' + item.unit.value + ')';
                var labels = _.map(data, function (i) {
                    return moment(i.x).format('ll')
                });
                var color = colors[Math.floor(Math.random() * colors.length)];

                chart.data.labels = labels;
                chart.data.datasets = [
                    {
                        data: data,
                        label: label,
                        borderColor: color,
                        backgroundColor: color,
                        fill: false
                    }
                ];

                chart.update();
            }
        }

    }

})();