/**
 * Created by amine on 22/02/2017.
 */

(function () {

    'use strict';

    class EncasementDetailsCtrl {
        constructor(configService, paymentService) {
            this.configService = configService;
            this.paymentService = paymentService;


            this.encasement = {};
            this.encasementId = null;
            this.header = false;
        }

        static get $inject() {
            return ['configService', 'paymentService'];
        }

        $onInit() {
            this.isDental = this.configService.isDental();
            if (!_.isNil(this.encasementId)) this.loadEncasement()
            else this.prepareDetails()
        }

        loadEncasement() {
            this.paymentService.getEncasement(this.encasementId)
                .then(data => {
                    this.encasement = data;
                    this.prepareDetails();
                }, this.paymentService["mainState"]);
        }

        prepareDetails() {
            _.forEach(this.encasement.payments, (payment) => {
                _.forEach(payment.details, detail => {
                    if (_.has(detail, "visit")) {
                        detail.payable = detail.visit;
                        detail.date = detail.visit.visit_date;
                        detail.type = "visit";
                    }

                    if (_.has(detail, "treatment_plan")) {
                        detail.payable = detail.treatment_plan;
                        detail.date = detail.treatment_plan.creation_date;
                        detail.type = "plan";
                    }
                });
            })
        }

    }


    module.exports = {
        controller: EncasementDetailsCtrl,
        controllerAs: "vm",
        bindings: {
            encasement: "<encasement",
            encasementId: "<id",
            header: "<header"
        },
        template: require('payment/views/encasement-details.tpl.html'),
    };

})();
