/**
 * Created by amine on 27/03/2017.
 */
(function () {

    'use strict';

    class SavCtrl {
        constructor(system) {
            this.hddStatus = system.hdd;
        }

        static get $inject() {
            return ["system"];
        }
    }

    module.exports = {
        controllerAs: "vm",
        controller: SavCtrl,
        template: `
            <span class="hdd-progress" ng-style="{width: vm.hddStatus.percent + '%', background: vm.hddStatus.color}"></span>
            <div layout="row" ng-style="{'border-color': vm.hddStatus.color}">
                <md-icon md-font-icon="mdi-headset" md-font-set="mdi" aria-label="S.A.V." class="sav-icon flex-order-2"></md-icon>   
                <div class="phone-numbers layout-row flex-order-1">
                    <div class="phone-number flex" ng-style="{'border-color': vm.hddStatus.color}">
                        <span>0665 02 71 92</span>
                    </div>
                    <div class="phone-number flex" ng-style="{'border-color': vm.hddStatus.color}">
                        <span>0663 43 13 00</span>
                    </div>
                    <div class="phone-number flex" ng-style="{'border-color': vm.hddStatus.color}">
                        <span>0522 29 46 15</span>
                    </div>
                </div>  
                <md-tooltip md-delay="150" md-direction="bottom">
                    <span translate-once="hdd_status_tooltip" translate-values="vm.hddStatus"></span>
                </md-tooltip>
            </div>
        `,
    };


})();