/**
 * Created by amine on 25/12/2018.
 */
(function () {
    'use strict';

    class ReportAsTypeDialogCtrl {
        constructor($mdDialog, listService) {
            this.dialog = $mdDialog;
            this.listService = listService;

            this.item = this.item || {
                special_instance: null,
                value: ""
            };
        }

        static get $inject() {
            return ["$mdDialog", "listService"];
        }

        $onInit() {
            //console.log(this.item);
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            const query = _.assign({}, this.item, {
                model_name: "ObservationEntryType",
                special_instance: _.assign(
                    {_module: "exam.models", _model: "ExamTemplate"}, this.item.special_instance
                )
            });

            this.listService.handleList(query).then(this.dialog.hide);
        }

        templateChanged() {
            this.item.value = _.get(this.item, "special_instance.full_name", "")
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: ReportAsTypeDialogCtrl,
        template: require("shared/views/report-as-entry-type.tpl.html")
    }

})();

