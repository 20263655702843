(function () {
    'use strict';

    module.exports = measurementBlock;

    measurementBlock.$inject = ["measureService", "$compile", "$mdDialog", "$q", "configService"];

    function measurementBlock(measureService, $compile, $mdDialog, $q, configService) {

        let directive = {
            restrict: 'E',
            compile: compileFunc,
            scope: {
                measurement: "=?measurement",
                patient: "<?mnPatient",
                visit: "<mnVisit",
                consultation: "<mnConsultation",
                maxDate: "=?",
                readonly: "<?"
            }
        };

        function compileFunc(element, attrs) {
            attrs['elem'] = element.html();
            element.empty();
            return measurementBlockLink;
        }

        function measurementBlockLink(scope, element, attrs) {
            scope.loadMeasurement = loadMeasurement;
            scope.downloadGrowthChart = downloadGrowthChart;

            scope.changeValue = changeValue;

            scope.addNew = addNew;
            scope.historic = historic;
            scope.showChart = measureService.showChart;

            scope.$watchCollection('measurement', recalculate);

            if (!element.is('[mn-visit]'))
                measureService
                    .getMeasures()
                    .then(_.get(scope, 'readonly', false) ? loadInitMeasurement : loadElement);


            let killTimeout = null;
            let firstCall = true;

            if (element.is('[max-date]')) {
                scope.$watch('maxDate', function (newVal) {
                    if (!firstCall && !_.isNil(newVal)) loadInitMeasurement();
                })
            }

            scope.$watch('visit', function (newVal) {
                if (firstCall && _.isUndefined(newVal)) {
                    firstCall = false;
                    return;
                } else {
                    if (_.isUndefined(newVal)) measureService.getMeasures().then(loadElement);
                    else loadInitMeasurement();
                }
            });

            let measures = [];
            let calculated = [];

            let calculateFormula = require('shared/utils/calculate-formula');

            let usePediatricChartDirectDownload = false;

            function loadInitMeasurement() {
                $q.all([measureService['getMeasurementResume']({
                    patient_id: scope.patient,
                    visit_id: scope.visit,
                    consultation_id: scope.consultation,
                    limit_date: scope.maxDate
                }), configService.get("pediatric_chart_config")])
                    .then(success, _.noop);

                function success(data) {
                    scope.usePediatricChart = _.get(data[1], "use_module", false);
                    usePediatricChartDirectDownload = _.get(data[1], "direct_download_mode", false);
                    scope.pediatricChartDownloading = false;
                    data = data[0];

                    if (_.isUndefined(scope.measurement) || _.isNull(scope.measurement)) scope.measurement = {};

                    loadElement(data['measures']);

                    if (!_.isUndefined(data['measurements']['id'])) {
                        scope.measurement['id'] = data['measurements']['id'];
                        _.unset(data['measurements'], 'id');
                    }

                    scope.measurement['measure_values'] = data['measurements'];

                    recalculate();
                    generateTooltip(scope.measurement);
                }
            }

            function loadElement(data) {
                measures = data;
                let $div = _.reduce(data, function (div, item, index) {
                    let $element = $(require(`patient/measure-blocks/${item.type}-field.tpl.html`));
                    let label = `${item.name}${_.isNull(item.unit) ? '' : ' (' + item.unit.value + ')'}`;

                    let ngModelValue = `measurement.measure_values["${item.id}"].value`;
                    let ngChangeValue = `changeValue(measurement.measure_values["${item.id}"])`;

                    if (item.type === "float" || item.type === "integer") {
                        $element.attr('label', label);
                        $element.attr('ng-model', ngModelValue);
                        $element.attr('ng-change', ngChangeValue);
                        $element.attr("ng-disabled", "readonly");
                    }

                    if (item.type === "boolean") {
                        $element.text(label);
                        $element.attr('ng-model', ngModelValue);
                        $element.attr('ng-change', ngChangeValue);
                        $element.attr("ng-disabled", "readonly");
                    }

                    if (item.type === "string") {
                        $('label', $element).text(label);
                        $('input', $element).attr('ng-model', ngModelValue);
                        $('input', $element).attr('ng-change', ngChangeValue);
                        $('input', $element).attr("ng-disabled", "readonly");
                    }

                    if (item.type === "date") {
                        $('label', $element).text(label);
                        $('md-datepicker', $element).attr('ng-model', ngModelValue);
                        $('md-datepicker', $element).attr('ng-change', ngChangeValue);
                        $('md-datepicker', $element).attr("ng-disabled", "readonly");
                    }

                    if (item.type === "calculated") {
                        calculated.push(item);

                        scope[item.name] = calculateFormula(item['formula'], scope.measurement, measures);
                        $element.attr('label', label);
                        $element.attr('ng-model', item.name);
                    }


                    if (_.eq(index, 0)) $element.attr('md-autofocus', true);

                    return div.append($element);
                }, $('<div class="flex layout-row layout-wrap block-measurement" />'));

                element.append(attrs['elem']);
                element.append($div);
                element.removeAttr('elem');

                $compile(element.contents())(scope);

                $('input', element).on("focus", focus);
            }

            function focus() {
                $(this).select();

                clearTimeout(killTimeout);
                killTimeout = null;
            }

            function changeValue(value) {
                if (_.isEmpty(scope.measurement)) return;

                if (!_.isUndefined(scope.patient)) scope.measurement['patient'] = {id: scope.patient};
                if (!_.isEmpty(value) && !_.isEmpty(value['date'])) _.unset(value, 'date');

                recalculate();
                if (!_.isUndefined(scope.visit)) killTimeout = _.delay(startSave, 500);
            }

            function startSave() {
                measureService.handleVisitMeasurement(scope.visit, scope.measurement, scope.patient).then(loadMeasurement);
            }

            function recalculate() {
                _(calculated).forEach(function (item) {
                    scope[item.name] = calculateFormula(item['formula'], scope.measurement, measures);
                });
            }

            function loadMeasurement() {
                measureService['getMeasurementResume']({
                    patient_id: scope.patient,
                    visit_id: scope.visit,
                    consultation_id: scope.consultation,
                    limit_date: scope.maxDate,
                    with_measures: false
                }).then(success);

                function success(data) {
                    if (!_.isUndefined(data['measurements']['id'])) {
                        scope.measurement['id'] = data['measurements']['id'];
                        _.unset(data['measurements'], 'id');
                    }

                    _.forEach(data['measurements'], (value, key) => {
                        if (!_.isEmpty(value)) scope.measurement['measure_values'][key] = value;
                    })

                    recalculate();
                    generateTooltip(scope.measurement);
                }
            }

            function generateTooltip(measurement) {
                _(measures).forEach(function (item) {
                    let date = _.get(measurement['measure_values'][_.toString(item.id)], 'date', null);
                    let value = _.get(measurement['measure_values'][_.toString(item.id)], 'value', null);

                    let $element = $(`[ng-model='measurement.measure_values["${item.id}"].value']`, element);

                    if (!_.isNull(date) && !_.isNull(value)) {
                        $element.removeClass('mn-visit-measure');
                        let tooltip = generateTooltipItem(item);
                        $element.append(tooltip);
                        $compile(tooltip)(scope);
                    } else if (!_.isNull(value)) {
                        $element.addClass('mn-visit-measure');
                    }

                });
            }

            function generateTooltipItem(item) {
                return $(`
                    <md-tooltip md-delay="150" md-direction="top" ng-if='measurement.measure_values[\"${item.id}\"].date'>
                        <span ng-bind='measurement.measure_values[\"${item.id}\"].date'></span>
                    </md-tooltip>
                `)
            }

            function downloadGrowthChart() {
                scope.pediatricChartDownloading = true;
                measureService.downloadGrowthChart(scope.patient, usePediatricChartDirectDownload)
                    .then(() => scope.pediatricChartDownloading = false);
            }

            function addNew($event) {
                $mdDialog.show(_.assign(require('../dialogs/measurement-dialog'), {
                    targetEvent: $event,
                    locals: {
                        measures: measures,
                        patient: scope.patient,
                    }
                })).then(loadMeasurement);
            }

            function historic($event) {
                $mdDialog.show(_.assign(require('../dialogs/measurement-history-dialog'), {
                    targetEvent: $event,
                    locals: {
                        patient: scope.patient
                    }
                })).then(loadMeasurement);
            }

        };

        return directive;

    };

})();