/**
 * Created by BETALOS on 16/03/2016. [edited by amine 15/10/2019]
 */

(function () {

    'use strict';

    const {Subject} = require("rxjs");

    class ListCtrl {
        constructor(listService, $mdDialog, $attrs, $timeout, $scope, $element) {
            this.dialog = $mdDialog;
            this.$timeout = $timeout;
            this.listService = listService;
            this.$attrs = $attrs;
            this.$scope = $scope;
            this.$element = $element;
            this.ngModelController = this.ngModelController || null;

            this.items = [];
            this.initItems = [];
            this.internalValue = null;
            this.eventSubject = new Subject();
        }

        static get $inject() {
            return ["listService", "$mdDialog", "$attrs", "$timeout", "$scope", "$element"];
        }

        $onInit() {
            this.backendModel = this.$attrs['mnModel'];
            this.dynamicLabel = this.$attrs['mnLabel'];
            this.locked = _.isNil(this.locked) ? false : this.locked;
            this.dialogTpl = _.isString(this.$attrs['mnTpl']) ? this.$attrs['mnTpl'] : "default";

            this.listService.list(this.backendModel, true).then(items => {
                this.items = this.initItems = this.orderItems(items);
            });

            this.ngModelController.$render = () => this.renderCurrentValue();
        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.value) {
                this.value = this.ngModelController.$viewValue;
            }

            this.internalValue = this.value;
        }

        internalValueChanged() {
            this.value = this.internalValue;
            this.ngModelController.$setViewValue(this.internalValue);
            this.ngModelController.$commitViewValue();
        }

        setItems(items) {
            const item = _.find(this.initItems, ['id', _.get(this.internalValue, 'id', null)]);
            this.items = _.chain(items).pushOrUpdate(item).compact().uniqBy('id').sortBy('order').value();
        }

        mdSelectEvent(event) {
            this.eventSubject.next(event);
        }

        cancel() {
            this.internalValue = null;
            this.internalValueChanged();
        }

        add(ev) {
            const dialog = _.assign({}, require('../dialogs/list-dialog'), {
                template: require(`shared/views/list.${this.dialogTpl}.tpl.html`),
                targetEvent: ev,
                locals: {
                    mnLabel: this.dynamicLabel,
                    mnModel: this.backendModel
                },
            });

            this.dialog.show(dialog).then(data => {
                this.initItems = this.orderItems(_.concat(this.initItems, data));

                this.internalValue = data;
                this.internalValueChanged();
            });
        }

        orderItems(items) {
            return _.sortBy(items, 'order');
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($elem, attrs) {
        const ngRequired = $elem.is("[ng-required]") ? 'ng-required="vm.required"' : "";
        const isRequired = $elem.is("[required]") ? "required" : "";
        const ngDisabled = !_.isUndefined(attrs['ngDisabled']) ? 'ng-disabled="vm.disabled"' : "";
        const isDisabled = $elem.is('[disabled]') ? "disabled" : "";

        if ($elem.is("[mn-disabled]") || $elem.is("[mn-required]")) console.error("{ mn-disabled | mn-required } are removed use { ng-disabled | ng-required }");

        const label = attrs['mnLabel'];

        let search = $elem.is('[no-search]') ? "" : `
            <mn-select-search-header subject="vm.eventSubject" search-by="value" items="vm.setItems($event)" init-items="vm.initItems"></mn-select-search-header>
        `;

        $elem
            .addClass("layout-row")
            .addClass("layout-align-start-center");

        if (!$elem.is('[flex]') && !$elem.is('.flex')) $elem.addClass("flex-nogrow");

        return `
            <md-input-container ${_.isNil(attrs['mnLabel']) ? 'md-no-float' : ''}>
                ${_.isNil(attrs['mnLabel']) ? '' : `<label translate-once="${label}"></label>`}
                <md-select aria-label="select" md-on-open="vm.mdSelectEvent('open')" md-on-close="vm.mdSelectEvent('close')"  
                ng-model="vm.internalValue" ng-model-options="{ trackBy: '$value.id' }" ng-change="vm.internalValueChanged()"
                       ${ngRequired} ${isRequired} ${ngDisabled} ${isDisabled}>
                       ${search}
                       <md-option ng-if="vm.items" ng-repeat="item in vm.items track by item.id" ng-value="item">
                           <span ng-bind="::item.value"></span>
                       </md-option>
                </md-select>
                <div class="mn-option-buttons flex-nogrow layout-row">
                    <md-button ${isDisabled} ng-if="!vm.disabled && !vm.locked" class="md-icon-button" ng-click="vm.add($event)" aria-label="add" tabindex="-1">
                        <md-icon md-font-icon="mdi-plus" md-font-set="mdi"></md-icon>
                    </md-button>
                    <md-button ${isDisabled} ng-if="vm.value && !vm.disabled" class="md-icon-button" ng-click="vm.cancel()" aria-label="cancel" tabindex="-1">
                        <md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>
                    </md-button>
                </div>
            </md-input-container>
        `;
    }

    module.exports = {
        template: tpl,
        bindings: {
            disabled: "=?ngDisabled",
            required: "=?ngRequired",
            locked: "<?lockAdd"
        },
        controllerAs: "vm",
        controller: ListCtrl,
        require: {ngModelController: "ngModel"}
    };

})();
