/**
 * Created by BETALOS on 19/06/2017.
 */
(function () {
    "use strict";

    module.exports = {
        multiple: true,
        controller: MeasurementHistoryDialogCtrl,
        controllerAs: "vm",
        template: require("patient/views/measurement-history-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: true,
        bindToController: true
    };

    MeasurementHistoryDialogCtrl.$inject = ["$mdDialog"];

    function MeasurementHistoryDialogCtrl($mdDialog) {
        var vm = this;
        vm.cancel = $mdDialog.hide;
    }


})();