(function () {
    'use strict';

    const VISIT_SUB_LINKS = require('parameters/json/visit-sub-links.json');

    PatientCtrl.$inject = ["frontDeskService", "patientService", "careSheetService", "$mdToast", "$translate", "$mdDialog", "visitService", "$state", "externalConnectionService", "configService", "billingService"];

    function PatientCtrl(frontDeskService, patientService, careSheetService, $mdToast, $translate, $mdDialog, visitService, $state, externalConnectionService, configService, billingService) {
        let vm = this;

        const archiveAction = {
            icon: 'mdi-package-down',
            label: 'patient_archive',
            resource: 'patient',
            action: 'get',
            behavior: 'remove',
            method: archive
        };

        const unarchiveAction = {
            icon: 'mdi-package-up',
            label: 'patient_archive_out',
            resource: 'patient',
            action: 'get',
            behavior: 'remove',
            method: unarchive
        };

        const deleteAction = {
            icon: 'mdi-delete',
            label: 'patient_delete',
            resource: 'patient',
            action: 'delete',
            behavior: 'remove',
            method: remove
        };

        const privatizeAction = {
            icon: 'mdi-eye-off',
            label: 'patient_hide',
            resource: 'patient',
            specialPower: true,
            method: privatize
        };

        const unprivatizeAction = {
            icon: 'mdi-eye',
            label: 'patient_unhide',
            resource: 'patient',
            specialPower: true,
            method: unprivatize
        };

        const restoreAction = {
            icon: 'mdi-delete-restore',
            label: 'patient_restore',
            resource: 'patient',
            action: 'delete',
            behavior: 'remove',
            method: restore
        };

        const sendSmsAction = {
            icon: 'mdi-email-check',
            label: 'send_sms',
            resource: 'sms',
            action: 'get',
            behavior: 'remove',
            method: sendSms
        };

        vm.$onInit = init;
        vm.show = show;

        function init() {
            configService.get("patient_list_grouped_tab")
                .then(value => {
                    if (value) {
                        vm.activatedTab = "all";
                        vm.groupedTabs = true;
                    } else {
                        vm.activatedTab = "active";
                        vm.groupedTabs = false;
                    }

                    vm.refresh({
                        search: patientService.patientFilter(vm.activatedTab)
                    }, true);
                });
            visitService.visitSubLinks.subscribe(data => {
                let linkConfig = _.find(VISIT_SUB_LINKS, ['key', data.favorite]);

                vm.actions = {
                    single: [
                        {
                            icon: 'mdi-pencil',
                            label: 'edition',
                            resource: 'patient',
                            action: 'update',
                            behavior: 'disable',
                            condition: "!item.is_deleted && !item.is_archived",
                            method: patientService.goToEdition
                        },
                        _.assign(
                            {
                                resource: 'visit',
                                action: 'create',
                                behavior: 'remove',
                                condition: "!item.is_deleted && !item.is_archived"
                            },
                            {
                                icon: linkConfig.icon,
                                label: linkConfig.tooltip,
                                method: (p, e) => frontDeskService.startShowVisitPatientFile(p, e, linkConfig.link)
                            }
                        ),
                        {
                            icon: 'mdi-cash-multiple',
                            label: 'financial_status',
                            resource: 'visit',
                            action: 'get',
                            behavior: 'remove',
                            condition: "!item.is_deleted",
                            method: gotoFinancialStatus
                        },
                        {
                            icon: 'mdi-calendar-plus',
                            label: 'new_rdv',
                            resource: 'appointment',
                            action: 'create',
                            behavior: 'remove',
                            condition: "!item.is_deleted && !item.is_archived",
                            method: frontDeskService.addNewAppointment
                        },
                        {
                            icon: 'mdi-calendar-text',
                            label: 'planning_module_label',
                            resource: 'appointment',
                            action: 'create',
                            behavior: 'remove',
                            condition: "!item.is_deleted && !item.is_archived",
                            method: patientService.goToPlanning
                        },
                        {
                            icon: 'mdi-account-alert',
                            label: 'patient_alerts',
                            resource: 'notification',
                            action: 'create',
                            behavior: 'remove',
                            condition: "!item.is_deleted && !item.is_archived",
                            method: patientService.patientAlerts
                        },
                        {
                            icon: 'mdi-folder-plus',
                            label: 'patient_care_sheet',
                            resource: 'visit',
                            action: 'create',
                            behavior: 'remove',
                            condition: "!item.is_deleted && !item.is_archived",
                            method: (item, ev) =>  careSheetService.patientCareSheet(item, ev)
                        },
                        {
                            icon: 'mdi-certificate',
                            label: 'subscription_folder',
                            resource: 'contract',
                            action: 'get',
                            behavior: 'remove',
                            method: billingService.goToContract
                        }
                    ],
                    multiple: [],
                    dynamicMultiple: []
                }
            });
        }

        function show(value) {
            vm.activatedTab = value;
            vm.actions['dynamicMultiple'] = multipleAction(value);
            vm.refresh({
                search: patientService.patientFilter(value)
            }, true)
        }

        function multipleAction(value) {
            if (_.indexOf(["all", "active", "favorite", "incomplete", "no-visit"], value) > -1) {
                return [archiveAction, deleteAction, privatizeAction, unprivatizeAction, sendSmsAction];
            }

            if (value === "archived") {
                return [unarchiveAction, deleteAction, privatizeAction, unprivatizeAction];
            }

            if (value === "deleted") return [restoreAction];
        }

        function toast(key) {
            const simpleToast = $mdToast.simple()
                .hideDelay(1500)
                .position("bottom left")
                .textContent($translate['instant'](key));

            $mdToast.show(simpleToast);
        }

        function archive(list) {
            vm.promise = patientService.archivePatients(list)
                .then(success);

            function success() {
                toast('patient_toast_archive');
                vm.refresh({
                    search: patientService.patientFilter(vm.activatedTab)
                }, true);
            }
        }

        function unarchive(list) {
            vm.promise = patientService.unarchivePatients(list)
                .then(success);

            function success() {
                toast('patient_toast_unarchive');
                vm.refresh({
                    search: patientService.patientFilter(vm.activatedTab)
                }, true);
            }
        }

        function remove(list) {
            const names = _.flatMap(list, "full_name").join("__BR__")

            const confirm = $mdDialog.confirm()
                .title($translate['instant']("patient_remove_confirm_title"))
                .htmlContent(_.replace($translate['instant']("patient_remove_confirm_text", {names: `- ${names}`}), new RegExp("__BR__", "g"), "<br />- "))
                .ariaLabel('confirm')
                .targetEvent(null)
                .ok($translate['instant']('confirm_ok'))
                .cancel($translate['instant']('confirm_cancel'));

            $mdDialog.show(confirm).then(reset);

            function reset() {
                vm.promise = patientService.removePatients(list)
                    .then(() => vm.refresh({
                        search: patientService.patientFilter(vm.activatedTab)
                    }, true));
            }
        }

        function restore(list) {
            vm.promise = patientService.restorePatients(list)
                .then(() => vm.refresh({
                    search: patientService.patientFilter(vm.activatedTab)
                }, true));
        }

        function privatize(list) {
            vm.promise = patientService.privatizePatients(list)
                .then(success);

            function success() {
                toast('patient_toast_privatized');
                vm.refresh({
                    search: patientService.patientFilter(vm.activatedTab)
                }, true);
            }
        }

        function unprivatize(list) {
            vm.promise = patientService.unprivatizePatients(list)
                .then(success);

            function success() {
                toast('patient_toast_unprivatized');
                vm.refresh({
                    search: patientService.patientFilter(vm.activatedTab)
                }, true);
            }
        }

        function sendSms(list, $event) {
            const ids = _.map(list, 'id');

            externalConnectionService
                .showSMSDialog($event, "send_patient_sms", ids);
        }

        function gotoFinancialStatus(data) {
            $state.go('app.patient-financial-statement', {id: data.id});
        }
    }


    module.exports = PatientCtrl;

})();
