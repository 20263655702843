/**
 * Created by Amine on 02/03/2017.
 */
(function () {
    "use strict";

    module.exports = mnSrefActive;

    mnSrefActive.$inject = ["$rootScope", "$state"];

    function mnSrefActive($rootScope, $state) {

        var directive = {
            restrict: 'A',
            scope: {
                active: "=mnSrefActive",
            },
            link: link,
        };

        function link($scope, $element, attrs) {
            $scope.$watch(watchState, checkState);

            function watchState() {
                return $state.$current['name']
            }

            function checkState(newValue, oldValue) {
                _.forEach($scope.active, function (value, key) {
                    $element.toggleClass(value, _.includes($state.current.name, key));
                });
            }
        }

        return directive;
    }

})();
