/**
 * Created by Issmahane on 27/12/2016.
 */
(function () {

    'use strict';

    const PROCEDURE_DIALOG = require("shared/dialogs/procedure-search-dialog");

    let mnSearchProcedure = {
        controller: MnSearchProcedureController,
        controllerAs: "vm",
        bindings: {
            callback: "&mnCallback",
            disabled: "=?mnDisabled",
        },
        template: tpl, // or template
    };

    MnSearchProcedureController.$inject = ["$mdDialog"];

    function MnSearchProcedureController($mdDialog) {
        let vm = this;

        vm.$onInit = init;
        vm.show = show;

        function init() {}

        function show($event) {
            $mdDialog.show(_.assign({}, PROCEDURE_DIALOG, {targetEvent: $event})).then(function (data) {
                vm.callback({data});
            });
        }

    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl(elem, attrs) {
        let icon = _.isNil(attrs['icon']) ?  "mdi-plus" : attrs['icon'];
        let classes = _.isNil(attrs['classes']) ? '' : attrs['classes'];
        let isNotFirst = elem.is('[is-not-first]') ? 'not-first-button' : '';
        let label = _.isNil(attrs['label']) ? "procedure_search" : attrs['label'];
        let disabled = _.isNil(attrs['mnDisabled']) ? '' : " ng-disabled=\"vm.disabled\" ";

        return [
            '<md-button '+ disabled +' class="md-raised mn-button-icon '+ isNotFirst + classes +' not-last-button" ng-click="vm.show($event)" aria-label="find model">',
            '   <md-icon md-font-icon="'+ icon +'" md-font-set="mdi"></md-icon>',
            '   <span translate-once="'+ label +'"></span>',
            '</md-button>'
        ].join('');
    }

    module.exports = mnSearchProcedure;

})();