(function () {

    'use strict';

    module.exports = ProcedurePrescriptionFormCtrl;

    ProcedurePrescriptionFormCtrl.$inject = ["$q", "system", "prescriptionService", "$transition$", "$mdDialog", "$scope"];

    function ProcedurePrescriptionFormCtrl($q, system, prescriptionService, $transition$, $mdDialog, $scope) {
        let vm = this;
        let dateFormat = system['date_format'].js;

        let currentPatient = _.get($transition$.params('to'), 'pId');
        let currentVisit = _.get($transition$.params('to'), 'visitId');
        let currentPrescription = _.get($transition$.params('to'), 'prescriptionId');
        let currentCopy = _.get($transition$.params('to'), 'copy');

        vm.editorOpts = {};
        vm.editorConfigKey = "procedure_prescription";

        vm.$onInit = init;
        vm.submit = submit;
        vm.callback = callback;
        vm.saveModel = saveModel;
        vm.addEmptyDetail = addEmptyDetail;

        vm.cancel = goBack;
        vm.deleteItem = prescriptionService.deleteItem;

        function init() {
            prescriptionService.prescriptionType.next({type: 'ProcedurePrescription'});

            prescriptionService.itemActive.next({id: -1, type: null});
            prescriptionService.itemDuplicated.next({id: -1, type: null});
            prescriptionService.itemEdited.next({id: currentPrescription, type: 'ProcedurePrescription'});

            vm.currentPatient = currentPatient;
            vm.editorName = _.uniqueId("procedure-prescription-");

            let promise = [];

            if (!_.isNull(currentCopy)) {
                prescriptionService.itemDuplicated.next({id: currentCopy, type: 'ProcedurePrescription'});
                promise.push(prescriptionService.getProcedurePrescription(currentCopy))
            }
            else if (!_.isNull(currentPrescription)) promise.push(prescriptionService.getProcedurePrescription(currentPrescription));
            else promise.push(createEmptyProcedurePrescription());


            $scope.$on("$destroy", onDestroy);
            vm.promise = $q.all(promise).then(success);

            let itemDeletedSubscription = prescriptionService.itemDeleted.subscribe(itemDeleted);
            let itemAddedSubscription = prescriptionService.addProcedurePrescriptionSubject.subscribe(itemAdded);

            function success(data) {
                let actualData = _.head(data);

                vm.prescription = _.isNull(currentCopy) ? actualData : _.assign(_.pick(actualData, 'details'), {
                    visit: {id: currentVisit},
                    prescription_date: moment().format(dateFormat)
                });


                if (_.isNull(currentCopy)) {
                    vm.editModel = actualData['is_model'];

                    prescriptionService.prescriptionType.next({
                        type: 'ProcedurePrescription',
                        model: vm.editModel
                    })
                }
            }

            function itemAdded(id) {
                vm.promise = prescriptionService.getProcedurePrescription(id)
                    .then(itemAddedSuccess);
            }

            function itemAddedSuccess(prescription) {
                vm.prescription.details = _.chain(vm.prescription.details)
                    .concat(prescription.details)
                    .uniqWith(function (a, b) {
                        if (_.isNil(a.procedure) || _.isNil(b.procedure)) return false;
                        else return a.procedure.id == b.procedure.id;
                    })
                    .value();
            }

            function createEmptyProcedurePrescription() {
                return {
                    visit: {id: currentVisit},
                    prescription_date: moment().format(dateFormat),
                    details: []
                }
            }

            function itemDeleted(item) {
                if (_.isEqual(item, {
                    id: currentPrescription,
                    prescription_type: 'ProcedurePrescription'
                })) prescriptionService.listState();
            }

            function onDestroy() {
                itemDeletedSubscription.unsubscribe();
                itemAddedSubscription.unsubscribe();
            }
        }

        function submit(quit) {
            return prescriptionService.saveProcedurePrescription(vm.prescription, currentPatient)
                .then(success);

            function success(data) {
                if (vm.editModel) prescriptionService.modelAdded.next(data);
                else prescriptionService.itemAdded.next(data);

                if (quit === "list") return prescriptionService.listState();
                if (quit === "back") return goBack();

                if (_.isNull(currentPrescription)) {
                    vm.prescription = data;
                    currentPrescription = data.id;
                    prescriptionService.refreshState(data);
                }
            }
        }

        function saveModel($event) {
            let confirm = $mdDialog.mnPromptDialog()
                .title('exam_model_title')
                .event('prescription.ProcedurePrescription.title_validation')
                .placeholder('title')
                .targetEvent($event)

            $mdDialog.show(confirm).then(success);

            function success(data) {
                let model = _.assign(_.pick(vm.prescription, 'details'), {title: data});

                prescriptionService.saveProcedurePrescription(model)
                    .then(success);

                function success(data) {
                    prescriptionService.modelAdded.next(data);
                }
            }
        }

        function callback(data) {
            _.forEach(data, addDetail);
        }

        function addDetail(item) {
            let newDetail = {
                code: item.code,
                name: item.name,
                qte: 1,
                comment: "",
                procedure: item
            }
            let itemExist = _.find(vm.prescription.details, {name: item.name, procedure: {id: item.id}});

            if (_.isUndefined(itemExist)) vm.prescription.details.push(newDetail);
            else itemExist.qte += 1;
        }

        function addEmptyDetail() {
            let newDetail = {
                name: null,
                qte: 1,
                code: "",
                comment: ""
            }
            vm.prescription.details.push(newDetail);
        }

        function goBack() {
            return window.history.back();
        }
    }

})();