/**
 * Created by amine on 19/07/2017.
 */
(function () {

    'use strict';

    let mnInputSearchModal = {
        controller: MnInputSearchModalController,
        controllerAs: "vm",
        bindings: {
            filter: "=?mnQuery",
            choose: '=?mnChoose',
            callback: '<?mnCallback',
            remove: '<?mnRemove',
            disabled: "=ngDisabled",
            model: "=ngModel"
        },
        require: {ngModelController: "ngModel"},
        template: tpl
    };

    MnInputSearchModalController.$inject = ["modalService", "patientService", "$attrs"];

    function MnInputSearchModalController(modalService, patientService, $attrs) {
        let vm = this;

        let modalId = "patient_modal";
        let componentId = _.get($attrs, "componentId", "default");
        let subject = _.format('{0}.{1}', componentId, modalId);
        let currentValue = null;

        vm.$onInit = init;
        vm.show = show;
        vm.cancel = cancel;

        function init() {
            let nEqual = {"$ne": true};
            let modalSubscription = modalService.subject.subscribe(modalCallBack);

            if (!_.isFunction(vm.callback)) vm.callback = _.noop;
            if (!_.isFunction(vm.remove)) vm.remove = _.noop;

            if (_.isNil(vm.filter)) vm.filter = {
                is_archived: nEqual,
                is_deleted: nEqual,
                is_draft: nEqual,
                has_visit: true
            };

            vm.choose = _.isUndefined(vm.choose) ? false : vm.choose;
            vm.$onDestroy = onDestroy;

            function onDestroy() {
                modalSubscription.unsubscribe();
            }

            vm.ngModelController.$formatters = _.castArray(formatter);

            function formatter(valueFromModel) {
                const newValue = _.get(valueFromModel, "id");
                if (_.has(valueFromModel, "id") && currentValue !== newValue)
                    setPatient(_.get(valueFromModel, "id"));

                if (_.isNull(valueFromModel)) cancel(null);

                return valueFromModel;
            }
        }

        function show($event) {
            if (!_.isNil($event)) $event.stopPropagation();
            if (vm.disabled) return;
            if (_.isFunction(vm.filter)) modalService.showModal(modalId, vm.filter(), componentId, $event);
            else modalService.showModal(modalId, vm.filter, componentId, $event);
        }

        function cancel($event) {
            if (!_.isNil($event)) $event.stopPropagation();
            vm.choose = false;
            vm.ngModelController.$setViewValue(null);
            vm.ngModelController.$commitViewValue();
            vm.ngModelController.$render();
            vm.remove();
        }

        function modalCallBack(msg) {
            if (_.eq(msg.subject, subject)) {
                vm.choose = true;
                setPatient(msg.data.id);
            }
        }

        function setPatient(id) {
            currentValue = id;
            patientService.getMinimalPatient(id)
                .then(function (data) {
                    vm.ngModelController.$setViewValue(data);
                    vm.ngModelController.$commitViewValue();
                    vm.ngModelController.$render();
                    vm.callback(data);
                });
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl(elem, $attrs) {
        let label = _.get($attrs, "placeholder", "payment_choose_patient")

        elem.addClass("layout-row");

        return `<md-input-container ng-click="vm.show()">
                    <input ng-model="vm.model.full_name" aria-label="patient fullname" readonly translate-once-placeholder="${label}">
                    <div class="mn-option-buttons flex-nogrow layout-row">
                        <md-button ng-if="!vm.disabled" class="md-icon-button" ng-click="vm.show($event)" aria-label="search">
                            <md-icon md-font-icon="mdi-magnify" md-font-set="mdi"></md-icon>
                        </md-button>
                        <md-button ng-if="vm.choose && !vm.disabled" class="md-icon-button" ng-click="vm.cancel($event)" aria-label="cancel">
                            <md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>
                        </md-button>
                    </div>
                </md-input-container>`;
    }

    module.exports = mnInputSearchModal;

})();