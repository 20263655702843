/**
 * Created by BETALOS on 15/02/2017.
 */
(function () {
    "use strict";

    module.exports = {
        controller: MeasurementDialogCtrl,
        controllerAs: "vm",
        template: require("patient/views/measurement-form-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: true,
        bindToController: true,
        multiple: true,
    };

    MeasurementDialogCtrl.$inject = ["$mdDialog", "measureService", "system"];

    function MeasurementDialogCtrl($mdDialog, measureService, system) {
        let vm = this;
        let dateFormat = system['date_format'].js;
        let timeFormat = system['time_format'].js;

        vm.measurement = !_.isNil(vm.measurement) ? vm.measurement : {
            date: {
                date: moment().format(dateFormat),
                time: moment().format(timeFormat)
            },
            patient: {
                id: vm.patient
            }
        };

        vm.submit = submit;
        vm.cancel = $mdDialog.cancel;

        function submit() {
            if (_.isNil(vm.measurement.patient)) vm.measurement.patient = {id: vm.patient};
            measureService['handleMeasurement'](vm.measurement, vm.patient)
                .then($mdDialog.hide);
        }
    }

})();