(function () {

    'use strict';

    class PatientRelationsDialogCtrl {
        constructor($mdDialog, patientService, $q) {
            this.dialog = $mdDialog;
            this.patientService = patientService;
            this.$q = $q;

            this.patient = this.patient || null;
            this.relations = [];
            this.toSave = [];
            this.toDelete = [];

            this.relationTypes = [
                {value: 'PARENT', label: "patient_relations_parent"},
                {value: 'CHILD', label: "patient_relations_child"},
                {value: 'SIBLING', label: "patient_relations_sibling"},
                {value: 'SPOUSE', label: "patient_relations_spouse"},
                {value: 'COUSIN', label: "patient_relations_cousin"},
                {value: 'AUNT_UNCLE', label: "patient_relations_aunt_uncle"},
                {value: 'NIECE_NEPHEW', label: "patient_relations_niece_nephew"},
                {value: 'GREAT_PARENT', label: "patient_relations_great_parent"},
                {value: 'GRAND_CHILD', label: "patient_relations_grand_child"},
                {value: 'EMPLOYER', label: "patient_relations_employer"},
                {value: 'NURSE', label: "patient_relations_nurse"},
            ];
        }

        static get $inject() {
            return ["$mdDialog", "patientService", "$q"];
        }

        $onInit() {
            this.patientService.getPatientRelations(this.patient.id)
                .then(data => this.relations = data);

            this.patientCallback = patient => this.selectPatient(patient);
            this.query = () => this.patientsQuery();
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.patientService.updatePatientRelations(this.patient.id, this.toSave, this.toDelete)
                .then(() => this.dialog.hide(true));
        }

        deleteRelation(item, $index, real = true) {
            if (real) {
                item.to_delete = true;
                this.toDelete.push(item.id)
            } else {
                this.toSave.splice($index, 1);
            }
        }

        selectPatient(patient) {
            if (!_.isNil(patient)) {
                this.toSave.push({
                    left: _.pick(this.patient, 'id'),
                    right: patient,
                    relation: null
                })
            }
            return this.$q.resolve(true);
        }

        patientsQuery() {
            const nEqual = {"$ne": true};

            return {
                "_id": {"$ne": this.patient.id},
                "is_deleted": nEqual,
                "is_archived": nEqual,
                "is_draft": nEqual
            }
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: PatientRelationsDialogCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("../views/patient-relations-dialog.tpl.html"),
    };


})();