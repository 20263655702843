(function () {

    'use strict';


    module.exports = {
        controller: periodicSetter,
        controllerAs: "vm",
        bindings: {
            start: "=",
            end: "="
        },
        template: tpl, // or template
    };

    periodicSetter.$inject = ["system"];

    function periodicSetter(system) {
        let vm = this;

        let dateFormat = system['date_format'].js;

        vm.$onInit = init;
        vm.intervalChanged = intervalChanged;

        function init() {}

        function intervalChanged() {
            if (_.eq(vm.interval, "TODAY")) today();
            if (_.eq(vm.interval, "TOMORROW")) tomorrow();
            if (_.eq(vm.interval, "WEEK")) week();
            if (_.eq(vm.interval, "NEXT_WEEK")) nextWeek();
            if (_.eq(vm.interval, "MONTH")) month();
            if (_.eq(vm.interval, "NEXT_MONTH")) nextMonth();
            if (_.eq(vm.interval, "NEXT_THREE_MONTH")) nextThreeMonth();

            function today() {
                vm.start = vm.end = moment().format(dateFormat);
            }

            function tomorrow() {
                vm.start = vm.end = moment().add(1, 'day').format(dateFormat);
            }

            function week() {
                vm.start = moment().startOf("week").format(dateFormat);
                vm.end = moment().endOf("week").format(dateFormat);
            }

            function nextWeek() {
                vm.start = moment().add(1, 'weeks').startOf("week").format(dateFormat);
                vm.end = moment().add(1, 'weeks').endOf("week").format(dateFormat);
            }

            function month() {
                vm.start = moment().startOf("month").format(dateFormat);
                vm.end = moment().endOf("month").format(dateFormat);
            }

            function nextMonth() {
                vm.start = moment().add(1, 'month').startOf("month").format(dateFormat);
                vm.end = moment().add(1, 'month').endOf("month").format(dateFormat);
            }

            function nextThreeMonth() {
                vm.start = moment().add(1, 'month').startOf("month").format(dateFormat);
                vm.end = moment().add(3, 'month').endOf("month").format(dateFormat);
            }
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl(elem, attrs) {
        return [
            '<div class="layout-column flex mn-radio-group-container">',
            '   <label translate-once="search_interval" for=""></label>',
            '   <md-radio-group ng-model="vm.interval" ng-change="vm.intervalChanged()" class="layout-row">',
            '       <md-radio-button value="TOMORROW" aria-label="tomorrow">',
            '           <span translate-once="tomorrow"></span>',
            '       </md-radio-button>',
            '       <md-radio-button value="WEEK" aria-label="this week">',
            '           <span translate-once="this_week"></span>',
            '       </md-radio-button>',
            '       <md-radio-button value="NEXT_WEEK" aria-label="next week">',
            '           <span translate-once="next_week"></span>',
            '       </md-radio-button>',
            '       <md-radio-button value="MONTH" aria-label="this month">',
            '           <span translate-once="this_month"></span>',
            '       </md-radio-button>',
            '       <md-radio-button value="NEXT_MONTH" aria-label="this month">',
            '           <span translate-once="next_month"></span>',
            '       </md-radio-button>',
            '       <md-radio-button value="NEXT_THREE_MONTH" aria-label="this month">',
            '           <span translate-once="next_three_month"></span>',
            '       </md-radio-button>',
            '   </md-radio-group>',
            '</div>'
        ].join('')
    }

})();