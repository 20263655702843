/**
 * Created by Issmahane on 11/11/2016.
 */
(function () {

    'use strict';

    const {HTMLS} = require('shared/utils/requires');
    const LIST_DIALOG = require('../dialogs/list-dialog');

    class MultiSelectController {
        constructor(listService, $mdDialog, $attrs) {
            this.dialog = $mdDialog;
            this.service = listService;

            this.model = $attrs['mnModel'];
            this.label = $attrs['mnLabel'];
            this.tpl = _.isString($attrs['mnTpl']) ? $attrs['mnTpl'] : "default";
        }

        static get $inject() {
            return ["listService", "$mdDialog", "$attrs"];
        }

        $onInit() {
            this.tplParams = _.isNil(this.tplParams) ? {} : this.tplParams;
            this.ngModelController = this.ngModelController ? this.ngModelController : null;

            this.service.list(this.model).then(data => this.items = data);
        }

        isSelected(item) {
            return _.find(this.ngModelController.$modelValue, {id: item.id});
        }

        selectItem(item) {
            this.ngModelController.$setViewValue(
                _[this.isSelected(item) ? 'without' : 'concat'](this.ngModelController.$modelValue, item)
            );

            this.ngModelController.$commitViewValue();
        }

        cancel() {
            this.ngModelController.$setViewValue([]);
            this.ngModelController.$commitViewValue();
        }

        add(ev) {
            const tplPath = HTMLS[`shared/views/list.${this.tpl}.tpl.html`];

            this.dialog.show(_.assign({}, LIST_DIALOG, {
                targetEvent: ev,
                locals: {
                    mnLabel: this.label,
                    mnModel: this.model,
                    tplParams: this.tplParams
                },
                template: tplPath,
            })).then(data => this.items.push(data));
        }

    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl(element, attrs) {
        element.addClass('layout-row');

        const isDisabled = $(element).is('[disabled]') ? "disabled" : "";
        const disabled = !_.isNil(attrs['mnDisabled']) ? 'ng-disabled="vm.disabled"' : "";

        return `<md-content class="flex">
                    <md-list>
                        <md-list-item ng-if="!vm.items || vm.items.length == 0" class="layout-row">
                            <div class="empty-content layout-row layout-align-start-center flex">
                                <md-icon md-font-icon="mdi-alert-circle-outline" md-font-set="mdi" aria-label="warning"></md-icon>
                                <span translate-once="no_element_to_show"></span>
                            </div>
                        </md-list-item>
                        <md-list-item ng-class="{selected: vm.isSelected(item)}" ng-repeat="item in vm.items track by item.id" 
                                ng-click="vm.selectItem(item)" aria-label="item" ${disabled} ${isDisabled}>
                            <p ng-bind="item.value"></p>
                            <md-icon md-font-icon="mdi-check" md-font-set="mdi" ng-show="vm.isSelected(item)"></md-icon>
                        </md-list-item>
                    </md-list>
               </md-content>
               <div class="mn-option-buttons mn-left-padding layout-column">
                    <md-button ng-if="!vm.mnDisabled" class="md-icon-button" ng-click="vm.add($event)" aria-label="add" tabindex="-1">
                        <md-icon md-font-icon="mdi-plus" md-font-set="mdi"></md-icon>
                    </md-button>
                    <md-button ng-if="!vm.mnDisabled" class="md-icon-button" ng-click="vm.cancel()" aria-label="cancel" tabindex="-1">
                        <md-icon md-font-icon="mdi-close" md-font-set= "mdi"></md-icon>
                    </md-button>
               </div>`;
    }

    module.exports = {
        controllerAs: "vm",
        controller: MultiSelectController,
        bindings: {
            list: "=ngModel",
            change: "&ngChange",
            disabled: "=mnDisabled",
            tplParams: "<?tplParams"
        },
        require: {
            ngModelController: 'ngModel'
        },
        template: tpl, // or template
    };

})();