/**
 * Created by BETALOS on 18/03/2016.[edited by amine 16/10/2019]
 */

(function () {

    'use strict';

    const {Subject} = require("rxjs");

    class LocationCtrl {
        constructor(locationService, $mdDialog, $scope, $attrs, $timeout, $element) {
            this.dialog = $mdDialog;
            this.$timeout = $timeout;
            this.locationService = locationService;
            this.$attrs = $attrs;
            this.$scope = $scope;
            this.$element = $element;

            this.eventSubject = new Subject();
            this.ngModelController = this.ngModelController || null;

            this.child = null;
            this.parent = null;
            this.internalValue = null;

            this.items = [];
            this.initItems = [];
            this.localItems = [];
        }

        static get $inject() {
            return ["locationService", "$mdDialog", "$scope", "$attrs", "$timeout", "$element"];
        }

        $onInit() {
            this.backendModel = this.$attrs['mnModel'];
            this.dynamicLabel = this.$attrs['mnLabel'];

            this.locationService.list(this.backendModel, false).then(data => this.initItems = this.localItems = _.sortBy(data, 'full_name'));
            this.ngModelController.$render = () => this.renderCurrentValue();
        }

        $onChanges(changes) {
            if (!this.backendModel) return;
            if (changes.parent) this.filter(this.parent);
            if (changes.child) this.setValue(this.child);
        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.value) {
                this.value = this.ngModelController.$viewValue
            }

            this.internalValue = this.value;
        }

        internalValueChanged() {
            this.value = this.internalValue;
            this.ngModelController.$setViewValue(this.internalValue);
            this.ngModelController.$commitViewValue();
        }

        setItems(items) {
            if (this.localItems.length === 0) return;

            const item = _.find(this.initItems, ['id', _.get(this.internalValue, 'id', null)]);

            if (item) {
                this.items = _.chain(items).pushOrUpdate(item).uniqBy('id').sortBy('full_name').value();
            } else {
                this.items = items;
                this.cancel();
            }
        }

        mdSelectEvent(event) {
            this.eventSubject.next(event);
        }

        cancel() {
            this.internalValue = null;
            this.internalValueChanged();
        }

        add(ev) {
            const dialog = _.assign({}, require('../dialogs/location-dialog'), {
                targetEvent: ev,
                locals: {
                    mnLabel: this.dynamicLabel,
                    mnModel: this.backendModel
                }
            });

            this.dialog.show(dialog).then(data => {
                this.localItems.push(data);

                this.filter(this.parent);
                this.internalValue = data;
                this.internalValueChanged();
            });
        }

        filter(data) {
            if (this.localItems.length === 0) return;

            if (!data) {
                if (this.value) this.initItems = [];
                else this.initItems = _.sortBy(this.localItems, 'full_name');
            }
            else if (this.backendModel === "City") this.initItems = _.chain(this.localItems).filter(['country_id', data.id]).sortBy('full_name').value();
            else if (this.backendModel === "Province") this.initItems = _.chain(this.localItems).filter(['city_id', data.id]).sortBy('full_name').value();
            else this.initItems = _.sortBy(this.localItems, 'full_name');

            if (this.initItems.length === 0) this.cancel();
        }

        setValue(value) {
            if (_.isNil(value) || this.localItems.length === 0) return;

            const childKey = this.backendModel === "Country" ? 'country_id' : 'city_id';
            this.internalValue = _.find(this.localItems, {id: value[childKey]});

            this.internalValueChanged();
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($elem, attrs) {
        const label = attrs['mnLabel'];
        const isRequired = $elem.is("[required]") ? "required" : "";
        const isDisabled = $elem.is('[disabled]') ? "disabled" : "";
        const ngRequired = $elem.is("[ng-required]") ? "ng-required='vm.required'" : "";
        const ngDisabled = `ng-disabled="vm.items.length == 0${!attrs['ngDisabled'] ? ' || vm.disabled' : ''}"`;

        if ($elem.is("[mn-disabled]") || $elem.is("[mn-required]")) console.error("{ mn-disabled | mn-required } are removed use { ng-disabled | ng-required }");

        $elem
            .addClass("layout-row")
            .addClass("layout-align-start-center");

        if (!$elem.is('[flex]') && !$elem.is('.flex')) $elem.addClass("flex-nogrow");

        return `
            <md-input-container>
               <label translate-once="${label}"></label>
               <md-select md-on-open="vm.mdSelectEvent('open')" md-on-close="vm.mdSelectEvent('close')" ng-model="vm.internalValue" ng-model-options="{ trackBy: '$value.id' }"
                        ng-change="vm.internalValueChanged()" ${ngRequired} ${isRequired} ${ngDisabled} ${isDisabled}>
                   <mn-select-search-header subject="vm.eventSubject" search-by="full_name" items="vm.setItems($event)" init-items="vm.initItems"></mn-select-search-header>
                   <md-option ng-repeat="item in vm.items track by item.id" ng-value="item">
                       <span ng-bind="::item.full_name"></span>
                   </md-option>
               </md-select>
               <div class="mn-option-buttons flex-nogrow layout-row">
                   <md-button ${isDisabled} ng-if="!vm.disabled" class="md-icon-button" ng-click="vm.add($event)" aria-label="add" tabindex="-1">
                       <md-icon md-font-icon="mdi-plus" md-font-set="mdi"></md-icon>
                   </md-button>
                   <md-button ${isDisabled} ng-if="vm.value && !vm.disabled" class="md-icon-button" ng-click="vm.cancel()" aria-label="cancel" tabindex="-1">
                       <md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>
                   </md-button>
               </div>
            </md-input-container>
        `;
    }

    module.exports = {
        template: tpl,
        bindings: {
            child: '<',
            parent: '<',
            disabled: "=?ngDisabled",
            required: "=?ngRequired",
        },
        controllerAs: "vm",
        controller: LocationCtrl,
        require: {ngModelController: "ngModel"}
    };
})();
