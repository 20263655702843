/**
 * Created by BETALOS on 06/05/2016.
 */
(function () {

    'use strict';

    let mnSearchModal = {
        controller: MnSearchModalController,
        controllerAs: "vm",
        bindings: {
            mnQuery: '=?',
            choose: '=?mnChoose',
            selectOnly: '=?',
            callback: '<mnCallback',
            remove: '<mnRemove',
            disabled: "=mnDisabled",

        },
        template: tpl, // or template
    };

    MnSearchModalController.$inject = ["modalService", "$element", "$attrs"];

    function MnSearchModalController(modalService, element, $attrs) {
        let vm = this;

        let modalId = $attrs['modalId'];
        let componentId = !_.isUndefined($attrs['componentId']) ? $attrs['componentId'] : "default";

        let subject = `${componentId}.${modalId}`;

        vm.$onInit = init;

        vm.show = show;
        vm.cancel = cancel;

        function init() {
            vm.choose = _.isUndefined(vm.choose) ? false : vm.choose;
            vm.selectOnly = _.isUndefined(vm.selectOnly) ? false : vm.selectOnly;

            element.parent().addClass("layout-align-start-center");

            let modalSubscription = modalService.subject.subscribe(modalCallBack);

            vm.$onDestroy = onDestroy;

            function onDestroy() {
                modalSubscription.unsubscribe();
            }
        }

        function show(ev) {
            if (_.isFunction(vm.mnQuery)) modalService.showModal(modalId, vm.mnQuery(), componentId, ev);
            else modalService.showModal(modalId, vm.mnQuery, componentId, ev);
        }

        function cancel() {
            if (_.isFunction(vm.remove)) vm.remove();
            vm.choose = false;
        }

        function modalCallBack(msg) {
            if (_.eq(msg.subject, subject)) {
                vm.choose = true;
                vm.callback(msg.data);
            }

        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl(elem, attrs) {
        let mnDisabled = !_.isUndefined(attrs['mnDisabled']) ? ' ng-disabled="vm.disabled" ' : "";
        let fontIcon = !_.isUndefined(attrs['fontIcon']) ? attrs['fontIcon'] : "mdi-magnify";

        elem.addClass("layout-row");
        return `
            <div class="mn-opts" flex="nogrow">
                <md-button class="md-raised md-primary" ng-click="vm.show($event)" aria-label="find model" ${mnDisabled}>
                    <md-icon md-font-icon="${fontIcon}" md-font-set="mdi"></md-icon>
                </md-button>
                <md-button class="md-raised md-primary" ng-click="vm.cancel()" ng-if="vm.choose && !vm.selectOnly">
                    <md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>
                </md-button>
            </div>
        `;

    }

    module.exports = mnSearchModal;

})();