(function () {

    'use strict';

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: LocationDialogCtrl,
        template: require("../views/location-dialog.tpl.html"),
    };

    LocationDialogCtrl.$inject = ["$mdDialog", "locationService"];

    function LocationDialogCtrl($mdDialog, locationService) {
        let vm = this;

        vm.$onInit = init;

        vm.submit = submit;
        vm.cancel = $mdDialog.cancel;

        function init() {
            let parent = getParent(vm.mnModel);
            let parent_key = parent ? parent.toLowerCase() + '_id' : '';

            vm.location = _.assign(vm.location, {model_name: vm.mnModel});

            if (parent && _.has(vm.location, parent_key)) {
                vm.location[parent.toLowerCase()] = {id: vm.location[parent_key]};
            }

            vm.parent = parent;

            if (!_.isNil(vm.parent)) {
                locationService.list(vm.parent).then(success);
            }

            function success(data) {
                vm.items = data;
            }
        }

        function submit() {
            locationService.handleLocation(vm.location)
                .then($mdDialog.hide);
        }

        function getParent(son) {
            if (son === "City") return "Country";
            if (son === "Province") return "City";
            if (son === "Country") return;
        }
    }

})();