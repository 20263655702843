/**
 * Created by Amine on 27/07/2016.
 */
(function () {
    'use strict';

    module.exports = paymentService;

    paymentService.$inject = ["$http", "mnWebSocket", "$q", "$mdDialog", "$state", "$translate"];

    function paymentService($http, mnWebSocket, $q, $mdDialog, $state, $translate) {
        let self = this;

        self.addPayment = addPayment;
        self.saveEncasement = saveEncasement;
        self.getEncasement = getEncasement;

        self.validateDeliveryDocuments = validateDeliveryDocuments;

        self.visitPayments = visitPayments;
        self.resetVisitPayments = resetVisitPayments;
        self.refreshEntry = refreshEntry;
        self.payEntry = payEntry;
        self.prePayEntry = prePayEntry;
        self.payVisit = payVisit;
        self.closeVisit = closeVisit;
        self.reopenVisit = reopenVisit;
        self.exemptVisit = exemptVisit;
        self.unexemptVisit = unexemptVisit;
        self.applyDiscount = applyDiscount;
        self.applyAffectation = applyAffectation;
        self.getCls = getCls;
        self.getVisits = getVisits;
        self.getOrganizationVisits = getOrganizationVisits;
        self.getDentalTreatmentPlan = getDentalTreatmentPlan;
        self.getOrganizationTreatmentPlan = getOrganizationTreatmentPlan;
        self.getDentalConsultation = getDentalConsultation;
        self.getEncasements = getEncasements;
        self.encasementState = encasementState;
        self.resetEncasement = resetEncasement;
        self.deleteEncasement = deleteEncasement;
        self.mainState = mainState;
        self.getGeneralAccount = getGeneralAccount;
        self.getAccountDetails = getAccountDetails;
        self.dentalTreatmentPlanPayments = dentalTreatmentPlanPayments;
        self.resetDentalTreatmentPlanPayments = resetDentalTreatmentPlanPayments;
        self.payDentalTreatmentPlan = payDentalTreatmentPlan;
        self.closeDentalTreatmentPlan = closeDentalTreatmentPlan;
        self.reopenDentalTreatmentPlan = reopenDentalTreatmentPlan;
        self.exemptDentalTreatmentPlan = exemptDentalTreatmentPlan;
        self.unexemptDentalTreatmentPlan = unexemptDentalTreatmentPlan;

        function getCls(key) {
            let _cls = {
                visit: "VisitPaymentDetail",
                plan: "TreatmentPlanPaymentDetail",
                patient: {
                    _module: "patient.models",
                    _model: "Patient"
                },
                organization: {
                    _module: "shared.insurance.models",
                    _model: "Organization"
                },
                unregistered: {
                    _module: "payment.models",
                    _model: "UnregisteredPayer"
                }
            };

            return _.get(_cls, key, "PaymentDetail");
        }

        function addPayment(payment) {
            let deferred = $q.defer();
            let url = "/api/payment/";

            $http.post(url, payment)
                .then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function saveEncasement(encasement) {
            let deferred = $q.defer();
            let url = `/api/encasement-form/${encasement.id ? `${encasement.id}/` : ''}`;

            $http[encasement.id ? "put" : "post"](url, encasement).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function getEncasement(id) {
            let deferred = $q.defer();
            let url = "/api/encasement/" + id + "/";

            $http.get(url, {id: id})
                .then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function visitPayments(visit) {
            return mnWebSocket.call("payment.Payment.visit_payments", {id: visit.id})
        }

        function resetVisitPayments(visit) {
            return mnWebSocket
                .call("visit.VisitPayment.reset_payments", {id: visit.id});
        }

        function resetEncasement(id) {
            return mnWebSocket.call("payment.Encasement.reset", {id: id});
        }

        function deleteEncasement(encasement, ev, showMessage = true) {
            let deferred = $q.defer();
            let url = "/api/encasement/" + encasement.id + "/";

            if (showMessage) {
                let confirm = $mdDialog.confirm()
                    .title($translate['instant']("encasement_remove_confirm_title"))
                    .textContent($translate['instant']("encasement_remove_confirm_text"))
                    .ariaLabel('remove plan confirm')
                    .targetEvent(ev)
                    .ok($translate['instant']('confirm_ok'))
                    .cancel($translate['instant']('confirm_cancel'));

                $mdDialog.show(confirm).then(remove, deferred.reject);
            } else remove();

            function remove() {
                resetEncasement(encasement.id)
                    .then(() => {
                        $http.delete(url, {id: encasement.id})
                            .then(success, error)
                    });
            }

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function refreshEntry(entryId) {
            return function () {
                if (!_.isNil(entryId)) {
                    mnWebSocket.pub("frontdesk.Practice.entry_updated", {
                        reload: true,
                        id: entryId
                    }, false);
                }
            }
        }

        function payEntry($event, entry) {
            if (entry.has_encasement) {
                getEncasement(entry.encasement)
                    .then(encasement => {
                        if (encasement.total_amount === entry.visit_financial_status.total) {
                            const payment = {
                                total_amount: entry.visit_financial_status.total,
                                payment_date: encasement.encasement_date,
                                details: [{
                                    total_amount: entry.visit_financial_status.total,
                                    visit: {id: entry.visit_id},
                                    _cls: getCls('visit')
                                }],
                                encasement: {
                                    id: encasement.id
                                }
                            };

                            addPayment(payment).then(() => {
                                payVisit($event, entry.visit_id, entry).then(refreshEntry(entry.id), _.noop);
                            })
                        } else {
                            payVisit($event, entry.visit_id, entry).then(refreshEntry(entry.id), _.noop);
                        }
                    })
            } else {
                payVisit($event, entry.visit_id, entry).then(refreshEntry(entry.id), _.noop);
            }
        }

        function payVisit($event, visit, entry) {
            const dialog = require("payment/dialogs/entry-payment-dialog");

            return $mdDialog.show(_.assign({}, dialog, {
                targetEvent: $event,
                locals: {visit, entry}
            }), _.noop);
        }

        function prePayEntry($event, entry) {
            const dialog = require("payment/dialogs/entry-pre-payment-dialog");

            return $mdDialog.show(_.assign({}, dialog, {
                targetEvent: $event,
                locals: {entry}
            }));
        }

        function closeVisit($event, visit) {
            const deferred = $q.defer();
            const confirm = $mdDialog.confirm()
                .title($translate.instant('visit_closing_confirm'))
                .ariaLabel('close visit confirm')
                .targetEvent($event)
                .ok($translate.instant('confirm_ok'))
                .cancel($translate.instant('confirm_cancel'));

            $mdDialog.show(confirm).then(success);

            function success() {
                $q.all([mnWebSocket.call("visit.VisitPayment.close", _.pick(visit, 'id')),])
                mnWebSocket.call("visit.VisitPayment.close", _.pick(visit, 'id'))
                    .then(deferred.resolve, deferred.reject);
            }

            return deferred.promise;
        }

        function reopenVisit($event, visit) {
            const deferred = $q.defer();
            const confirm = $mdDialog.confirm()
                .title($translate.instant('visit_reopen_confirm'))
                .ariaLabel('reopen visit confirm')
                .targetEvent($event)
                .ok($translate.instant('confirm_ok'))
                .cancel($translate.instant('confirm_cancel'));

            return $mdDialog.show(confirm).then(success);

            function success() {
                mnWebSocket.call("visit.VisitPayment.reopen", _.pick(visit, 'id'))
                    .then(deferred.resolve, deferred.reject);
            }

            return deferred.promise;
        }


        function exemptVisit(visit, date) {
            let data = {
                is_exempt: true,
                exempt_at: date
            };

            return togglingVisitStatus(visit, data);
        }

        function unexemptVisit(visit) {
            let data = {
                is_exempt: false,
                exempt_at: null
            };

            return togglingVisitStatus(visit, data);
        }

        function togglingVisitStatus(visit, exempting_data) {
            let deferred = $q.defer();
            let url = `/api/visit/${visit.id}/`;

            $http.put(url, {financial_status: exempting_data})
                .then(success, error)

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function dentalTreatmentPlanPayments(plan) {
            return mnWebSocket.call("payment.Payment.dental_treatment_plan_payments", {id: plan.id})
        }

        function resetDentalTreatmentPlanPayments(visit) {
            // @ToDo need to be implemanted
            //return mnWebSocket.call("visit.VisitPayment.reset_payments", {id: visit.id});
        }

        function payDentalTreatmentPlan($event, entry) {
            const dialog = require("payment/dialogs/treatment-plan-payment-dialog");
            const entryId = entry.id, patientId = entry.patient.id;

            return $mdDialog.show(_.assign({}, dialog, {
                targetEvent: $event,
                locals: {entryId, patientId}
            }));
        }

        function closeDentalTreatmentPlan($event, visit) {
            const deferred = $q.defer();
            const confirm = $mdDialog.confirm()
                .title($translate.instant('dental_plan_closing_confirm'))
                .ariaLabel('close plan confirm')
                .targetEvent($event)
                .ok($translate.instant('confirm_ok'))
                .cancel($translate.instant('confirm_cancel'));

            $mdDialog.show(confirm).then(success);

            function success() {
                mnWebSocket.call("dental_consultation.TreatmentPlanPayment.close", _.pick(visit, 'id'))
                    .then(deferred.resolve, deferred.reject);
            }

            return deferred.promise;
        }

        function reopenDentalTreatmentPlan($event, visit) {
            const deferred = $q.defer();
            const confirm = $mdDialog.confirm()
                .title($translate.instant('dental_plan_reopen_confirm'))
                .ariaLabel('reopen plan confirm')
                .targetEvent($event)
                .ok($translate.instant('confirm_ok'))
                .cancel($translate.instant('confirm_cancel'));

            $mdDialog.show(confirm).then(success);

            function success() {
                mnWebSocket.call("dental_consultation.TreatmentPlanPayment.reopen", _.pick(visit, 'id'))
                    .then(deferred.resolve, deferred.reject);
            }

            return deferred.promise;
        }

        function exemptDentalTreatmentPlan(plan, date) {
            let data = {
                is_exempt: true,
                exempt_at: date
            };

            return togglingDentalTreatmentPlanStatus(plan, data);
        }

        function unexemptDentalTreatmentPlan(plan) {
            let data = {
                is_exempt: false,
                exempt_at: null
            };

            return togglingDentalTreatmentPlanStatus(plan, data);
        }

        function togglingDentalTreatmentPlanStatus(visit, exempting_data) {
            let deferred = $q.defer();
            let url = `/api/dental-treatment-plan/${visit.id}/`;

            $http.put(url, {financial_status: exempting_data})
                .then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function applyPercentage(type, total, value, percentage) {
            if (_.eq(type, "value")) {
                _.set(percentage.object, percentage.attr, _.round(_.get(value.object, value.attr, 0) / total, 6));
            } else {
                _.set(value.object, value.attr, total * _.round(_.get(percentage.object, percentage.attr, 0), 6));
            }
        }

        function applyDiscount(type, visit, payment) {
            let grossAmount = visit.financial_status['gross_total'];

            applyPercentage(type, grossAmount,
                {
                    object: visit.financial_status,
                    attr: "global_discount"
                },
                {
                    object: visit.financial_status,
                    attr: "global_discount_percentage"
                }
            );

            visit.financial_status.total = grossAmount - visit.financial_status.global_discount;

            if (!_.isNil(payment) && payment.amount > visit.financial_status.total) {
                payment.amount = visit.financial_status.total;
            }
        }

        function applyAffectation(type, payable, affectation, amount = null) {
            applyPercentage(type, amount ? amount : payable.financial_status.total, {
                object: affectation,
                attr: "affected_amount"
            }, {
                object: affectation,
                attr: "affected_percentage"
            })
        }

        function getVisits(query) {
            return mnWebSocket.call("payment.FinancialStatementVisit.list", query);
        }

        function getDentalTreatmentPlan(query) {
            return mnWebSocket.call("payment.FinancialStatementTreatmentPlan.list", query);
        }

        function getDentalConsultation(query) {
            return mnWebSocket.call("payment.FinancialStatementDentalConsult.list", query);
        }

        function getOrganizationVisits(query) {
            return mnWebSocket.call("payment.FinancialStatementVisit.organization_list", query);
        }

        function getOrganizationTreatmentPlan(query) {
            return mnWebSocket.call("payment.FinancialStatementTreatmentPlan.organization_list", query);
        }

        function getEncasements(query) {
            return mnWebSocket.call("payment.FinancialStatementEncasement.list", query);
        }

        function encasementState(encasement, reset) {
            const id = _.isUndefined(encasement) ? null : encasement.id;

            return $state.go("app.payment.form", {
                encasementId: id,
                reset: _.isNil(reset) ? null : reset
            }, {inherit: true});
        }

        function getGeneralAccount(query) {
            return mnWebSocket.call("payment.FinancialStatementGeneralAccount.list", query);
        }

        function getAccountDetails(pk, type = "P") {
            return mnWebSocket.call("payment.FinancialStatementGeneralAccount.account_details", type === "P" ? {patient: pk} : {organization: pk});
        }

        function mainState() {
            $state.go("app.payment.main", {}, {inherit: true});
        }

        function validateDeliveryDocuments(paymentId) {
            return mnWebSocket.call("payment.Payment.validate_delivery_documents", {id: paymentId});
        }
    }
})();

