(function () {

    'use strict';

    class SaveFilterCtrl {
        constructor(tableService, $mdDialog) {
            this.tableService = tableService;
            this.dialog = $mdDialog;
            this.filter = this.filter || null;
        }

        static get $inject() {
            return ['tableService', "$mdDialog"];
        }

        $onInit() {

        }

        submit(){
            this.tableService.handleFilter(this.filter).then(this.dialog.hide);
        }

        cancel() {
            this.dialog.cancel();
        }

    }

    module.exports = {
        multiple: false,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        controller: SaveFilterCtrl,
        template: require("shared/views/filter-dialog.tpl.html")
    };

})()