/**
 * Created by Amine 03/11/2017
 * used for a precise need, do not edit
 */
(function () {
    'use strict';


    var mnLegacyBiologyTable = {
        controller: mnLegacyBiologyTableCtrl,
        controllerAs: "vm",
        bindings: {
            details: '=prescriptionDetails',
        },
        template: require('prescription/views/legacy-biology-table.tpl.html'), // or template,
    }

    mnLegacyBiologyTableCtrl.$inject = [];

    function mnLegacyBiologyTableCtrl() {
        var vm = this;

        vm.$onInit = init;

        function init() {
        }
    }

    module.exports = mnLegacyBiologyTable;

})();
