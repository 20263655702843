/**
 * Created by BETALOS on 03/05/2017.
 */
(function () {

    'use strict';

    module.exports = {
        controller: DialogController,
        controllerAs: "vm",
        template: require("shared/views/contact-dialog.tpl.html"),
        parent: $(document.body),
        locals: {},
        bindToController: true,
        clickOutsideToClose: true,
        multiple: true
    };

    DialogController.$inject = ["$mdDialog", "physicianService", "contactService"];

    function DialogController($mdDialog, physicianService, contactService) {
        var vm = this;

        vm.$onInit = init;

        function init() {
            if (_.isNil(vm.contact)) vm.contact = {
                contact_info: {phone_numbers: []}
            }

            else vm.promise = (_.get(vm.contact, '_model') == "PhysicianContact" ? physicianService : contactService)['getFullContact'](vm.contact)
                .then(success);

            function success(data) {
                vm.contact = data;

                if(_.isNil(vm.contact.contact_info)) vm.contact.contact_info = {
                    phone_numbers: []
                }
            }
        }

        vm.submit = submit;
        vm.cancel = $mdDialog.cancel;

        function submit() {
            if (vm.isPhysician) physicianService.addContact(vm.contact)
                .then($mdDialog.hide);

            else contactService.addContact(vm.contact)
                .then($mdDialog.hide);
        }

    }

})();