(function () {

    'use strict';

    module.exports = prescriptionService;

    const {Subject} = require("rxjs");

    prescriptionService.$inject = ["$q", "mnWebSocket", "$http", "$state", "configService", "listService", "$translate", "$mdDialog"];

    function prescriptionService($q, mnWebSocket, $http, $state, configService, listService, $translate, $mdDialog) {
        let self = this;

        self.configuration = {};

        self.itemDeleted = new Subject();
        self.itemEdited = new Subject();
        self.itemActive = new Subject();
        self.itemDuplicated = new Subject();
        self.itemAdded = new Subject();
        self.modelAdded = new Subject();
        self.prescriptionType = new Subject();
        self.useBiologyModel = new Subject();

        self.addMedicalPrescriptionSubject = new Subject();
        self.addProcedurePrescriptionSubject = new Subject();
        self.addTextPrescriptionSubject = new Subject();

        self.getPrescription = getPrescription;
        self.getPrescriptions = getPrescriptions;
        self.getVisitPrescriptionsList = getVisitPrescriptionsList;

        self.getTextPrescriptions = getTextPrescriptions;
        self.getTextPrescriptionList = getTextPrescriptionList;
        self.getModelTextPrescriptionList = getModelTextPrescriptionList;
        self.getTextPrescription = getTextPrescription;
        self.saveTextPrescription = saveTextPrescription;

        self.getMedicalPrescriptionList = getMedicalPrescriptionList;
        self.getMedicalPrescriptions = getMedicalPrescriptions;
        self.getMedicalPrescription = getMedicalPrescription;
        self.saveMedicalPrescription = saveMedicalPrescription;
        self.getModelMedicalPrescriptionList = getModelMedicalPrescriptionList;
        self.getMedicalModelPrescription = getMedicalModelPrescription;
        self.getLastMedicalPrescription = getLastMedicalPrescription;

        self.getBiologyPrescriptionList = getBiologyPrescriptionList;
        self.getBiologyPrescriptions = getBiologyPrescriptions;
        self.getBiologyPrescription = getBiologyPrescription;
        self.getLegacyBiologyPrescription = getLegacyBiologyPrescription;
        self.saveBiologyPrescription = saveBiologyPrescription;
        self.getModelBiologyPrescriptionList = getModelBiologyPrescriptionList;
        self.getBiologyModelPrescription = getBiologyModelPrescription;
        self.saveBiologyPrescriptionResult = saveBiologyPrescriptionResult;
        self.saveBiologyPrescriptionRequest = saveBiologyPrescriptionRequest;

        self.getProcedurePrescriptionList = getProcedurePrescriptionList;
        self.getProcedurePrescriptions = getProcedurePrescriptions;
        self.getProcedurePrescription = getProcedurePrescription;
        self.saveProcedurePrescription = saveProcedurePrescription;
        self.getModelProcedurePrescriptionList = getModelProcedurePrescriptionList;
        self.getProcedureModelPrescription = getProcedureModelPrescription;

        self.listState = listState;
        self.editState = editState;
        self.refreshState = refreshState;
        self.duplicateState = duplicateState;

        self.deleteItem = deleteItem;
        self.printGroup = printGroup;

        self.getConfig = getConfig;

        self.constructedTitle = constructedTitle;
        self.getResult = getResult;

        self.getBiologyResume = getBiologyResume;

        // @todo vidal:tocheck
        //self.getLeaflet = getLeaflet;


        function getResult(requestId) {
            return mnWebSocket.call("prescription.BiologyPrescription.get_last_result", {
                request_id: requestId
            });
        }

        function getConfig() {
            let deferred = $q.defer();
            let config = {}

            configService.get("prescription_config")
                .then(first_step)

            function first_step(data) {
                config = data;

                let promises = [
                    listService.get(config.default_values['dosage_form'])
                ]

                $q.all(promises)
                    .then(second_step);
            }

            function second_step(data) {
                config.default_values['dosage_form'] = data[0];
                deferred.resolve(config);
            }

            return deferred.promise;
        }

        function getVisitPrescriptionsList(visit) {
            return $q.all(_.map([
                "MedicalPrescription",
                "ProcedurePrescription",
                "TextPrescription",
                "BiologyPrescriptionRequest",
                "BiologyPrescriptionResult"
            ], defer));

            function defer(eventSet) {
                return mnWebSocket.call(`prescription.${eventSet}.full_list`, {visit: _.isObject(visit) ? visit.id : visit})
            }
        }

        function getModelMedicalPrescriptionList() {
            return getList("medical-prescription-model");
        }

        function getModelTextPrescriptionList() {
            return getList("text-prescription-model");
        }

        function getModelBiologyPrescriptionList() {
            return getList("biology-prescription-model");
        }

        function getModelProcedurePrescriptionList() {
            return getList("procedure-prescription-model");
        }

        function getList(url) {
            const deferred = $q.defer();

            $http.get(`/api/${url}/`)
                .then(success, deferred.reject);

            function success(result) {
                deferred.resolve(result.data);
            }

            return deferred.promise;
        }

        function getTextPrescriptionList(patient) {
            return getByPatient("text-prescription-list", patient);
        }

        function getMedicalPrescriptionList(patient) {
            return getByPatient("medical-prescription-list", patient);
        }

        function getTextPrescriptions(patient) {
            return getByPatient("text-prescription", patient);
        }

        function getProcedurePrescriptions(patient) {
            return getByPatient("procedure-prescription", patient);
        }

        function getBiologyPrescriptions(patient) {
            return getByPatient("biology-prescription", patient);
        }

        function getBiologyPrescriptionList(patient) {
            return getByPatient("biology-prescription-list", patient);
        }


        function getProcedurePrescriptionList(patient) {
            return getByPatient("procedure-prescription-list", patient);
        }


        function getMedicalPrescriptions(patient) {
            return getByPatient("medical-prescription", patient);
        }

        function getByPatient(url, patient) {
            const deferred = $q.defer();
            const urlHandled = `/api/${url}/?patient=${patient}`;

            $http.get(urlHandled)
                .then(success, deferred.reject);

            function success(result) {
                deferred.resolve(result.data);
            }

            return deferred.promise;
        }

        function getTextPrescription(pk) {
            return get("text-prescription", pk);
        }

        function getMedicalPrescription(pk) {
            return get("medical-prescription", pk);
        }

        function getBiologyPrescription(pk, isResult) {
            if (isResult) return get("biology-prescription-result", pk);
            else return get("biology-prescription", pk);
        }

        function getLegacyBiologyPrescription(pk) {
            return get("biology-prescription-legacy", pk);
        }

        function getBiologyModelPrescription(pk) {
            return get("biology-prescription-model", pk);
        }

        function getProcedurePrescription(pk) {
            return get("procedure-prescription", pk);
        }

        function getProcedureModelPrescription(pk) {
            return get("procedure-prescription-model", pk);
        }

        function getMedicalModelPrescription(pk) {
            return get("medical-prescription-model", pk);
        }

        function get(url, pk) {
            const deferred = $q.defer();
            const urlUsed = `/api/${url}/${pk}/`;

            $http.get(urlUsed)
                .then(success, deferred.reject);

            function success(result) {
                deferred.resolve(result.data);
            }

            return deferred.promise;
        }

        function getPrescription(pk, type) {
            return self["get" + type](pk);
        }

        function getPrescriptions(pk, type) {
            return self["get" + type + "s"](pk);
        }

        function save(url, prescription, patient) {
            const deferred = $q.defer();
            const urlUsed = `/api/${url}/${prescription.id ? prescription.id + "/" : ''}${patient ? "?patient=" + patient : ''}`;

            $http.post(urlUsed, prescription)
                .then(success, deferred.reject);

            function success(result) {
                deferred.resolve(result.data);
            }

            return deferred.promise;
        }

        function getLastMedicalPrescription(patient) {
            return mnWebSocket.call("prescription.MedicalPrescription.get_last_prescription", {patient});
        }

        function saveTextPrescription(text_prescription, patient) {
            return save("text-prescription", text_prescription, patient);
        }

        function saveMedicalPrescription(medical_prescription, patient) {
            return save("medical-prescription", medical_prescription, patient);
        }

        function saveBiologyPrescription(biology_prescription, patient) {
            return save("biology-prescription", biology_prescription, patient);
        }

        function saveProcedurePrescription(procedure_prescription, patient) {
            return save("procedure-prescription", procedure_prescription, patient);
        }

        function saveBiologyPrescriptionResult(biology_prescription_result, patient) {
            return save("biology-prescription-result", biology_prescription_result, patient);
        }

        function saveBiologyPrescriptionRequest(biology_prescription_request, patient) {
            return save("biology-prescription-request", biology_prescription_request, patient);
        }

        function constructedTitle(item) {
            if (_.isUndefined(item)) return false;

            let translationKey = _.snakeCase(item['prescription_type']) + '_title';

            if (_.isNull(item.title) || _.isEmpty(item.title)) return $translate['instant'](translationKey, {
                date: item.prescription_date
            });

            return _.format("{0} ({1})", item.title, item.prescription_date);
        }

        function listState() {
            $state.go("app.visit.prescription.list");
        }

        function editState(item, reload = true) {
            let params = {
                prescriptionId: item['id'],
                copy: null,
                legacy: item['is_legacy'] ? 1 : null
            }

            if (item['is_result']) params['requestId'] = item['request_id'];
            else if (_.eq(item['prescription_type'], 'BiologyPrescription')) params['requestId'] = null;

            $state.go("app.visit.prescription." + _.kebabCase(item['prescription_type']), params, {
                inherit: true
            }).then(reload ? $state.reload : _.noop, _.noop);
        }

        function refreshState(item, supQuery = {}) {
            self.itemEdited.next({id: -1, type: null});
            self.itemDuplicated.next({id: -1, type: null});
            self.itemEdited.next({id: item.id, type: item['prescription_type']});

            $state.go("app.visit.prescription." + _.kebabCase(item['prescription_type']), _.assign({
                prescriptionId: item.id,
                copy: null
            }, supQuery), {location: 'replace'});
        }

        function duplicateState(pk, type, reload = true) {
            $state.go("app.visit.prescription." + _.kebabCase(type), {
                prescriptionId: null,
                requestId: null,
                copy: pk
            }).then(reload ? $state.reload : _.noop);
        }

        function deleteItem(item, ev) {
            let deferred = $q.defer();

            let url = _.format('/api/{0}/{1}/', _.kebabCase(item['prescription_type']), item.id);
            let confirmKey = item.is_model ? "prescription_model_remove_confirm" : "prescription_remove_confirm";

            let confirm = $mdDialog.confirm()
                .title($translate['instant'](confirmKey, _.pick(item, 'title')))
                .ariaLabel('remove plan confirm')
                .targetEvent(ev)
                .ok($translate['instant']('confirm_ok'))
                .cancel($translate['instant']('confirm_cancel'));

            $mdDialog.show(confirm).then(remove, deferred.reject);

            function remove() {
                $http.delete(url)
                    .then(success, deferred.reject);
            }

            function success() {
                self.itemDeleted.next(_.pick(item, ['id', 'prescription_type']));
                deferred.resolve(item.id);
            }

            return deferred.promise;
        }

        function printGroup(item) {
            let printGroup = _.get(item, 'prescription_type');
            if (printGroup == "BiologyPrescription") {
                if (_.get(item, 'request_id', false) || item['is_result'])
                    printGroup = "BiologyPrescriptionResult";
                else
                    printGroup = "BiologyPrescriptionRequest";
            }

            return _.kebabCase(printGroup);
        }


        // @todo vidal:tocheck
        /*function getLeaflet(item) {
            return mnWebSocket.call("prescription.VidalConfig.get_product_leaflet", item);
        }*/

        function getBiologyResume(patient, exams) {
            let query = {
                patient: patient,
                exams: _.map(exams, 'id')
            }

            return mnWebSocket.call("prescription.BiologyPrescriptionResult.biology_resume", query);
        }
    }

})();
