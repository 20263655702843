/**
 * Created by BETALOS on 30/09/2016.
 */
(function () {
    "use strict";

    module.exports = mnAge;

    mnAge.$inject = ["system", "$translate"];

    function mnAge(system,$translate) {

        var directive = {
            restrict: 'A',
            scope: {
                birthDate: "=mnAge",
                ngModel: "="
            },
            require: 'ngModel',
            link: ageLink,
        };

        function ageLink(scope, element, attrs, ctrl) {
            var dateFormat = system['date_format'].js;

            var month = _.toLower($translate.instant('month'));
            var isMonth = false;

            var $parent = element.parents('md-input-container');
            var $labelElem = $("label", $parent);

            var label = $labelElem.text();
            var monthLabel = label + ' (' + month + ')';

            scope.$watch('birthDate', birthDateChange);
            scope.$watch('ngModel', ageChanged);

            ctrl.$render = render;

            function birthDateChange(value) {
                var age = _.isNil(value) ? null : moment().diff(moment(value, dateFormat), 'years');
                var oldValue = ctrl.$viewValue;

                if(_.eq(age,  0)) {
                    isMonth = true;
                    age = moment().diff(moment(value, dateFormat), 'month');
                }
                else isMonth = false;

                ctrl.$viewValue = age;
                ctrl.$render(isMonth);
                if(!_.isNil(oldValue)) ctrl.$commitViewValue();
            }

            function ageChanged(value, oldValue) {
                if(_.eq(value, oldValue)) return;
                else if(!_.isNil(value) && !_.eq(value, moment().diff(moment(scope.birthDate, dateFormat), isMonth ? 'month' : 'years'))) {
                    scope.birthDate = moment().subtract(value, 'years').date(1).month(0).format(dateFormat);
                }
            }

            function render(isMonth) {
                handleLabel(isMonth);

                if(_.isNil(ctrl.$viewValue)){
                    $parent.removeClass('md-input-has-value');
                    element.val("");
                }
                else {
                    element.val(ctrl.$viewValue);
                    $parent.addClass('md-input-has-value');
                }

                function handleLabel(isMonth) {
                    if(_.isEmpty(label)) {
                        label = $labelElem.text();
                        monthLabel = label + ' (' + month + ')';
                    }
                    if(isMonth) $labelElem.text(monthLabel);
                    else $labelElem.text(label);
                }
            }
        }

        return directive;
    }

})();
