/**
 * Created by amine on 12/10/2018.
 */
(function () {
    'use strict';

    class SMSSendingDialogCtrl {
        constructor($mdDialog, externalConnectionService) {
            this.dialog = $mdDialog;
            this.externalConnectionService = externalConnectionService;

            this.models = [];
            this.content = '';
            this.query = this.query || null;
            this.event = this.event || 'send_sms';

            this.errors = [];
            this.showError = false;
        }

        static get $inject() {
            return ["$mdDialog", "externalConnectionService"];
        }

        $onInit() {
            this.externalConnectionService.getSMSModels()
                .then(data => {
                    this.models = data;
                })
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.externalConnectionService.sendSms(this.event, {
                content: this.content,
                query: this.query
            }).then(resp => this.validateResponse(resp));
        }

        validateResponse(resp) {
            this.errors = _.filter(resp, ["error", true])

            if (this.errors.length > 0) {

            } else {
                this.dialog.hide();
            }

        }

        addModel(item) {
            this.content += `\n${item.content}`;
        }

        useModel(item) {
            this.content = item.content;
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: SMSSendingDialogCtrl,
        template: require("shared/views/sms-sending-dialog.tpl.html")
    }

})()