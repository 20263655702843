/**
 * Created by amine on 11/09/2017.
 */
(function () {
    'use strict';

    class VisitLegend {
        constructor() {
            this.priority = 0;
            this.restrict = "E"
        }

        static create() {
            return new VisitLegend(...arguments);
        }

        compile(element, attrs) {
            element.addClass("legend-container").prepend(this.tpl());

            return (scope, element, attrs) => this.link(scope, element, attrs)
        }

        link(scope, element, attrs) {
            scope.includeTypes = scope.$eval(attrs.includeTypes);
        }

        tpl() {
            return `
                <div class="legend">
                   <div class="legend-group">
                       <div class="legend-item payment-status">
                           <span class="round-span paid"></span>
                           <span class="item-label" translate-once="paid_visit_label"></span>
                       </div>
                       <div class="legend-item payment-status">
                           <span class="round-span partial"></span>
                           <span class="item-label" translate-once="partially_paid_visit_label"></span>
                       </div>
                       <div class="legend-item payment-status">
                           <span class="round-span unpaid"></span>
                           <span class="item-label" translate-once="unpaid_visit_label"></span>
                       </div>
                       <div class="legend-item payment-status">
                           <span class="round-span"></span>
                           <span class="item-label" translate-once="exempt_visit_label"></span>
                       </div>
                   </div>
                   
                   <div class="legend-group">
                       <div class="legend-item left-spaced payment-status">
                           <span class="round-span visit"></span>
                           <span class="item-label" translate-once="payable_visit_label"></span>
                       </div>
                       <div class="legend-item payment-status">
                           <span class="round-span plan"></span>
                           <span class="item-label" translate-once="payable_treatment_plan_label"></span>
                       </div>
                   </div>
                </div>
            `;
        }
    }

    VisitLegend.create.$inject = [];

    module.exports = VisitLegend.create;

})();