/**
 * Created by BETALOS on 10/05/2016.
 */
(function () {

    'use strict';

    const VISIT_SUB_LINKS = require('parameters/json/visit-sub-links.json');

    PatientFormCtrl.$inject = [
        "patientService", "$mdToast", "$translate", "$transition$", "$mdDialog", "$state", "frontDeskService",
        "measureService", "$q", "visitService", "$auth", "$element", "$scope", "billingService"
    ];

    function PatientFormCtrl(
        patientService, $mdToast, $translate, $transition$, $mdDialog, $state, frontDeskService,
        measureService, $q, visitService, $auth, $element, $scope, billingService
    ) {
        let vm = this;

        let currentPatient = _.get($transition$.params('to'), 'patient_id');

        vm.$onInit = init;

        vm.measureBtn = measureBtn;
        vm.addMeasurement = addMeasurement;
        vm.measureChart = measureService.showChart;

        vm.clearDeath = clearDeath;
        vm.clearInsurance = clearInsurance;
        vm.handlePatient = handlePatient;

        vm.handleTitleChange = patientService.handleTitleChange;

        vm.resetPicture = resetPicture;

        vm.goBack = goBack;
        vm.isDraft = isDraft;

        vm.startShowVisit = startShowVisit;

        vm.patientAlerts = patientAlerts;

        vm.manageRelations = manageRelations;

        vm.previousPatient = previousPatient;
        vm.nextPatient = nextPatient;

        vm.goToContract = billingService.goToContract;

        function init() {
            vm.patientFormData = true;

            vm.promise = patientService.getFormPatient(currentPatient)
                .then(doneCallback, goBack);

            function doneCallback(data) {
                vm.patient = data;
                // patientService.nextPatient(vm.patient.id)
                //     .then(data => console.log(data));
                setTimeout(handlePatientImage);
            }

            visitService.visitSubLinks.subscribe(data => {
                vm.linkConfig = _.find(VISIT_SUB_LINKS, ['key', data.favorite]);
            });
        }

        function clearDeath() {
            if (!vm.patient['life_status']['declared_dead']) {
                vm.patient['life_status']['death_date'] = null;
                vm.patient['life_status']['reason_of_death'] = null;
            }
        }

        function clearInsurance(is_insured) {
            if (!is_insured) vm.patient.medical_insurances = [];
            else if (_.isNil(vm.patient.medical_insurances[0])) vm.patient.medical_insurances.push({
                affiliate: 'PATIENT'
            });
        }

        function resetPicture(ev) {
            ev.stopPropagation();

            vm.file = null;
            vm.patient.picture = null;
        }

        function handlePatientImage() {
            if (_.isNil(vm.patient.id)) $scope.$applyAsync(() => vm.noPatientImage = true);
            else vm.patientImgSrc = `/api/patient/${vm.patient.id}/data/?t=${moment().valueOf()}`;
        }

        function handlePatient(action, $event) {
            $event.stopPropagation();

            const deferred = $q.defer();

            if (_.has(vm.patient, 'id')) savePatient();
            else patientService.checkExistence(vm.patient).then(savePatient, failCall);

            function failCall(data) {
                const locals = {'patient-id': data.id};
                const assign = {clickOutsideToClose: false, escapeToClose: false, multiple: true};

                frontDeskService.entryResume(locals, $event, assign)
                    .then(toPatient, checkSavePatient);
            }

            function toPatient(id) {
                deferred.reject();

                $state.go("app.patient-form", {"patient_id": id})
                    .then($state.reload);
            }

            function checkSavePatient(use) {
                if (use) savePatient();
                else deferred.reject();
            }

            function savePatient() {
                patientService.patientEdition(vm.patient, vm.file)
                    .then(doneCallback);

                function doneCallback(data) {
                    if (action === "quit") return goBack();
                    else if (action === "quit_new") return goNewFile();
                    else if (action === "rdv") frontDeskService.addNewAppointment(data, $event);

                    if (!vm.patient.id) {
                        $state.go("app.patient-form", {"patient_id": data.id}, {location: 'replace'});
                    }

                    vm.patient = data;

                    const simpleToast = $mdToast.simple()
                        .textContent($translate['instant']('patient_edit_success'))
                        .position("bottom left")
                        .hideDelay(1500);

                    $mdToast.show(simpleToast);

                    deferred.resolve(data);
                }
            }

            return deferred.promise;
        }


        function goBack() {
            window.history.back();
        }

        function isDraft() {
            return vm.patient && (!vm.patient['birth_date'] || !_.includes(['MALE', 'FEMALE'], vm.patient.gender));
        }

        function startShowVisit(ev) {
            const deferred = $q.defer();

            handlePatient(false, ev).then(
                (p) => frontDeskService.startShowVisitPatientFile(p, ev, vm.linkConfig.link)
                    .then(deferred.resolve, deferred.reject)
            );

            return deferred.promise;
        }

        function patientAlerts(ev) {
            patientService.patientAlerts(vm.patient, ev);
        }

        function measureBtn(show) {
            vm.measureIcons = show;
        }

        function addMeasurement(ev) {
            measureService['measurementsComponent'].next(ev)
        }

        function manageRelations(ev) {
            patientService.manageRelations(vm.patient, ev);
        }

        function goNewFile() {
            $state.go("app.patient-form", {"patient_id": null}, {reload: true}).then($state.reload)
        }

        function previousPatient() {
            return patientService.previousPatient(vm.patient.id)
                .then(data => {
                    if (data) {
                        $state.go("app.patient-form", {"patient_id": data.id}, {notify: true}).then(() => $state.reload())
                    } else {
                        const simpleToast = $mdToast.simple()
                            .textContent($translate['instant']('patient_not_found'))
                            .position("bottom left")
                            .hideDelay(1500);

                        $mdToast.show(simpleToast);
                    }
                }, _.noop)
        }

        function nextPatient() {
            return patientService.nextPatient(vm.patient.id)
                .then(data => {
                    if (data) {
                        $state.go("app.patient-form", {"patient_id": data.id}, {notify: true}).then(() => $state.reload())
                    } else {
                        const simpleToast = $mdToast.simple()
                            .textContent($translate['instant']('patient_not_found'))
                            .position("bottom left")
                            .hideDelay(1500);

                        $mdToast.show(simpleToast);
                    }
                }, _.noop)
        }


    }

    module.exports = PatientFormCtrl;


})();
