(function () {
    'use strict';

    module.exports = EntryPaymentDialogCtrl;

    EntryPaymentDialogCtrl.$inject = ["visitService", "$mdDialog", "paymentService", "frontDeskService", "billingService", "$q"];

    function EntryPaymentDialogCtrl(visitService, $mdDialog, paymentService, frontDeskService, billingService, $q) {
        let vm = this;

        vm.$onInit = init;
        vm.submit = submit;
        vm.resetPayment = resetPayment;
        vm.addPayment = addPayment;
        vm.payerChange = payerChange;
        vm.cancel = $mdDialog.cancel;

        vm.applyDiscount = paymentService.applyDiscount;
        vm.applyAffectation = applyAffectation;
        vm.billingVisit = billingVisit;

        function init() {
            vm.readOnly = false;
            vm.newPayment = false;
            vm.lockPhysician = false;

            vm.payment = {};

            if (_.isInteger(vm.visit)) visitService.getVisit(vm.visit)
                .then(handleVisit);

            else handleVisit(vm.visit);

        }

        function handleVisit(data) {
            vm.visit = data;
            paymentService
                .visitPayments(vm.visit)
                .then(getVisitPayment);
        }

        function getVisitPayment(payments) {
            vm.isClosed = vm.visit.financial_status.is_closed;
            vm.readOnly = _.size(payments) > 0 || vm.visit.financial_status.is_exempt;

            let payee = {
                _module: "patient.models",
                _model: "Patient",
                id: vm.visit.patient_id
            };

            if (!vm.readOnly) {
                vm.visit.financial_status.init_discount = vm.visit.financial_status.global_discount;
                const gross_remaining_amount = vm.visit.financial_status.remaining_amount + vm.visit.financial_status.global_discount;
                vm.payment = _.assign(vm.payment, {
                    payer: payee,
                    payer_type: "P",
                    payee: payee,
                    payee_type: "P",
                    payment_date: moment(),
                    total: vm.newPayment ? gross_remaining_amount : vm.visit.financial_status.gross_total,
                    amount: vm.newPayment ? vm.visit.financial_status.remaining_amount : vm.visit.financial_status.total
                })
            } else {
                let payment = _.last(payments);
                let organization_affectation = _.get(vm.visit.financial_status, "organization_affectation", {});
                vm.payment = _.assign(vm.payment, {
                    payer: _.get(payment, "encasement.payer"),
                    payer_type: _.isEmpty(organization_affectation) ? _.get(payment, "encasement.payer_type") : 'T',
                    payment_mode: _.get(payment, "encasement.payment_mode"),
                    payee: payee,
                    payee_type: "P",
                    payment_date: vm.visit.financial_status.is_exempt ? vm.visit.financial_status.exempt_at : payment.payment_date,
                    total: vm.visit.financial_status.gross_total,
                    amount: vm.visit.financial_status.paid_amount,
                    organization_affectation: organization_affectation
                });
            }

            const physician = _.get(payments, "0.encasement.physician");
            vm.lockPhysician = !!physician;
            if (vm.lockPhysician) vm.payment.physician = physician;
        }

        function submit(event) {
            const deferred = $q.defer();
            const delivery_document = vm.visit.delivery_document;

            if (vm.visit.financial_status.is_exempt) {
                paymentService
                    .exemptVisit(vm.visit, vm.payment.payment_date)
                    .then(success, deferred.reject);
            } else {
                vm.visit.financial_status = _.assign(vm.visit.financial_status, {
                    paid_amount: vm.newPayment ? vm.visit.financial_status.paid_amount + vm.payment.amount : vm.payment.amount,
                    is_validated: true,
                    validate_at: moment(),
                    organization_affectation: vm.payment.payer_type === 'T' ? _.get(vm.payment, "organization_affectation") : null
                });

                let payment = {
                    total_amount: vm.payment.amount,
                    payment_date: vm.payment.payment_date,
                    details: [{
                        total_amount: vm.payment.amount,
                        visit: vm.visit,
                        _cls: paymentService.getCls('visit')
                    }],
                    encasement: {
                        encasement_date: vm.payment.payment_date,
                        physician: vm.payment.physician,
                        total_amount: vm.payment.amount,
                        payer: vm.payment.payer,
                        payee: vm.payment.payee,
                        payment_mode: vm.payment.payment_mode
                    }
                };

                paymentService
                    .addPayment(payment)
                    .then(success, deferred.reject);
            }

            function success(data) {
                if (_.get(vm.entry, "has_encasement")) {
                    const encasement = _.isNumber(vm.entry.encasement) ? {id: vm.entry.encasement} : vm.entry.encasement;
                    paymentService.deleteEncasement(encasement, event, false);
                }

                if (_.isNil(delivery_document)) {
                    deferred.resolve(true);
                    $mdDialog.hide(data);
                } else {
                    visitService.validateDeliveryDocument(delivery_document)
                        .then(data => {
                            deferred.resolve(true);
                            $mdDialog.hide(data)
                        });
                }
            }

            return deferred.promise;
        }

        function payerChange() {
            let organization = _.get(vm.visit, "insurance.organization");
            let patient = {
                _module: "patient.models",
                _model: "Patient",
                id: vm.visit.patient_id
            };
            switch (vm.payment.payer_type) {
                case "P":
                    vm.payment = _.assign(vm.payment, {
                        payer: patient
                    });
                    break;
                case "T":
                    vm.payment = _.assign(vm.payment, {
                        payer: patient,
                        organization_affectation: {
                            organization: organization,
                            affected_percentage: _.get(organization, "default_refund_amount", 0)
                        }
                    });
                    vm.applyAffectation('percentage', vm.visit, vm.payment.organization_affectation);
                    break;
                case "O":
                    vm.payment = _.assign(vm.payment, {payer: paymentService["getCls"]("unregistered")});
                    break;
            }
        }

        function resetPayment() {
            if (!_.isNil(vm.visit.delivery_document)) visitService.invalidateDeliveryDocument(vm.visit.delivery_document)
                .then(() => {
                    paymentService
                        .resetVisitPayments(vm.visit)
                        .then(resetForm)
                        .then(vm.entry ? paymentService.refreshEntry(vm.entry.id) : _.noop);
                });
            else paymentService
                .resetVisitPayments(vm.visit)
                .then(resetForm)
                .then(vm.entry ? paymentService.refreshEntry(vm.entry.id) : _.noop);
        }

        function resetForm(data) {
            vm.visit = data;

            vm.payment = {
                payer: {
                    _module: "patient.models",
                    _model: "Patient",
                    id: data.patient_id
                },
                payer_type: "P",
                payee: {
                    _module: "patient.models",
                    _model: "Patient",
                    id: data.patient_id
                },
                payee_type: "P",
            };
            getVisitPayment([]);
        }

        function addPayment() {
            vm.newPayment = true;
            resetForm(vm.visit);
        }

        function billingVisit(save = false) {
            let billingVisitData = _.assign({}, vm.visit, {patient: {id: vm.visit.patient_id}})

            if (save) return submit().then(() => billingService.billingVisit(billingVisitData));
            else {
                $mdDialog.hide();
                return billingService.billingVisit(billingVisitData);
            }
        }

        function applyAffectation(type, visit, affectation) {
            if (type === "value") {
                const affected_amount = parseFloat(_.get(affectation, "affected_amount", 0));

                if (affected_amount > 0) {
                    vm.payment.amount = parseFloat(_.get(visit, "financial_status.total", 0)) - affected_amount;

                    paymentService.applyAffectation(type, visit, affectation)
                }
            } else {
                const remaining_amount = parseFloat(_.get(visit, "financial_status.remaining_amount", 0));
                paymentService.applyAffectation(type, visit, affectation, remaining_amount);

                const affected_amount = parseFloat(_.get(affectation, "affected_amount", 0));
                vm.payment.amount = vm.payment.total - affected_amount;
            }
        }
    }
})();
