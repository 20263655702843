/**
 * Created by amine on 27/07/2016.
 */

(function () {
    'use strict';

    module.exports = {
        controller: "EntryPaymentDialogCtrl",
        controllerAs: "vm",
        template: require("payment/views/entry-payment.tpl.html"),
        targetEvent: null,
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true,
        multiple: true,
        onComplete: ($scope, $element) => {
            $element.find(".procedures-container").removeClass("hidden")
        }
    };
})();