/**
 * Created by BETALOS on 03/05/2017.
 */
(function () {

    'use strict';

    class ActiveModule {
        constructor(configService) {
            this.priority = 0;
            this.restrict = "A";
            this.service = configService;
        }

        static create() {
            return new ActiveModule(...arguments);
        }

        link(scope, element, attrs) {
            let key = attrs['mnActiveModule'];
            let behavior = _.get(attrs, 'behavior', 'remove');
            let value = _.get(this.service, `activeModules.${key}`, false);

            if (!value) {
                if (behavior === 'remove') element.remove();
                if (behavior === 'disabled') element.attr('disabled', 'disabled');
            }
        }
    }

    ActiveModule.create.$inject = ["configService"];

    module.exports = ActiveModule.create;

})();
