(function () {

    'use strict';

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: BiologyResumeDialogCtrl,
        template: require("../views/biology-resume-dialog.tpl.html"),
    };

    const {Subject} = require("rxjs");

    BiologyResumeDialogCtrl.$inject = ["$mdDialog", "prescriptionService", "system"];

    function BiologyResumeDialogCtrl($mdDialog, prescriptionService, system) {
        let vm = this;

        let dateFormat = system['date_format'].js;

        vm.exams = [];
        vm.subscriber = new Subject();

        vm.$onInit = init;
        vm.validate = validate;
        vm.getExamValue = getExamValue;

        vm.cancel = $mdDialog.cancel;

        function init() {
            vm.subscriber.subscribe(handleSubscribe);
        }

        function handleSubscribe(data) {
            let exam = data.exam;
            let index = _.findIndex(vm.exams, _.pick(exam, 'id'));

            if (index == -1) vm.exams.push(exam);
            else vm.exams.splice(index, 1);
        }

        function validate() {
            vm.promise = prescriptionService.getBiologyResume(vm.currentPatient, vm.exams)
                .then(success);

            function success(data) {
                vm.examHeader = examHeader();
                vm.examSubHeader = examSubHeader();
                vm.examBody = examBody(data);
            }
        }

        function examHeader() {
            return _.map(vm.exams, item => _.chain(item).pick(['id', 'title']).assign({
                length: item.details.length == 0 ? 1 : item.details.length
            }).value())
        }

        function examSubHeader() {
            return _.reduce(vm.exams, (items, item) => {
                return _.concat(items, item.details.length == 0 ? _.pick(item, ['id', 'is_unique']) : item.details);
            }, []);
        }

        function examBody(data) {
            return _.chain(data).groupBy('prescription_date').toPairs().sortBy((item) => {
                return -1 * moment(item[0], dateFormat).valueOf();
            }).value();
        }

        function getExamValue(exam, items) {
            return _.chain(items).map('details').flatten().find((element) => {
                return exam['is_unique'] ? exam.id == element.exam : _.has(element, 'value.' + exam.uid);
            }).get(exam['is_unique'] ? 'value' : 'value.' + exam.uid, '').value();
        }

    }

})();
