/**
 * Created by amine on 16/02/2017.
 */
(function () {
    'use strict';

    module.exports = FinancialStatementContainerCtrl;

    const {BehaviorSubject} = require("rxjs");

    FinancialStatementContainerCtrl.$inject = ["$state", "$transition$", "$translate", "paymentService", "mnWebSocket", "visitService", "configService", "$scope"];

    function FinancialStatementContainerCtrl($state, $transition$, $translate, paymentService, mnWebSocket, visitService, configService, $scope) {
        let vm = this;
        let currentPatient;
        let currentPatientSubscription = null;

        vm.$onInit = init;

        vm.onVisitReorder = onVisitReorder;
        vm.onVisitPaginate = onVisitPaginate;
        vm.getVisitData = getVisitData;

        vm.onEncasementReorder = onEncasementReorder;
        vm.onEncasementPaginate = onEncasementPaginate;
        vm.getEncasementData = getEncasementData;

        vm.onDentalReorder = onDentalReorder;
        vm.onDentalPaginate = onDentalPaginate;
        vm.getDentalData = getDentalData;

        vm.payVisit = payVisit;
        vm.closeVisit = closeVisit;
        vm.reopenVisit = reopenVisit;
        vm.showProcedures = showProcedures;
        vm.payDentalTreatmentPlan = payDentalTreatmentPlan;
        vm.closeDentalTreatmentPlan = closeDentalTreatmentPlan;
        vm.reopenDentalTreatmentPlan = reopenDentalTreatmentPlan;
        vm.showTeethProcedures = showTeethProcedures;
        vm.showEncasementDetails = showEncasementDetails;
        vm.filtersChange = filtersChange;

        vm.goBack = goBack;

        function init() {
            vm.accountSubject = new BehaviorSubject(null);

            vm.isDental = configService.isDental();
            vm.patient = null;
            vm.paginationLabel = {
                page: $translate['instant']('page'),
                rowsPerPage: $translate['instant']('rowsPerPage'),
                of: $translate['instant']('of')
            }

            vm.columns = [];
            vm.options = [20, 15, 10, 5];


            vm.selectedVisit = null;
            vm.visitPromise = null;
            vm.visits = [];
            vm.visitTotal = 0;
            vm.visitQuery = {
                limit: 10,
                page: 1,
                order: "-visit_date",
                hide_paid: false,
                hide_no_amount: true,
                is_exempt: true
            };
            vm.visitSummary = {
                gross_total: 0,
                paid_amount: 0,
                global_discount: 0,
                payment_status: 0,
                remaining_amount: 0
            };


            vm.selectedDental = null;
            vm.dentalPromise = null;
            vm.dental_plans = [];
            vm.dentalTotal = 0;
            vm.dentalQuery = {
                limit: 10,
                page: 1,
                order: "-creation_date",
                hide_paid: false,
                hide_no_amount: true,
                is_exempt: true
            };
            vm.dentalSummary = {
                gross_total: 0,
                paid_amount: 0,
                global_discount: 0,
                payment_status: 0,
                remaining_amount: 0
            };
            vm.selectedEncasement = null;
            vm.encasementPromise = null;
            vm.encasements = [];
            vm.encasementTotal = 0;
            vm.encasementQuery = {
                limit: 10,
                page: 1,
                order: "-encasement_date",
                start_date: null,
                end_date: null,
                payment_mode: null,
                physician: null
            };
            vm.encasementSummary = {
                total_amount: 0,
                consumed_amount: 0,
                remaining_amount: 0
            };

            vm.balance = 0;
            vm.patientContainer = !_.startsWith($state.$current.name, "app.visit");

            currentPatient = _.get($transition$.params('to'), vm.patientContainer ? "id" : "pId");
            vm.selectedTab = _.get($transition$.params('to'), 'tab');
            vm.origin = _.get($transition$.params('to'), 'origin');

            if (vm.patientContainer) mnWebSocket
                .call("patient.PatientMinimal.retrieve", {pk: currentPatient})
                .then(handlePatient);

            else currentPatientSubscription = visitService.currentPatientSubject.subscribe(handlePatient);

            getVisitData();
            getDentalData();
            getEncasementData();

            function handlePatient(data) {
                vm.patient = data;
                vm.accountSubject.next(currentPatient);
            }

            $scope.$on("$destroy", onDestroy);

            function onDestroy() {
                if (!_.isNull(currentPatientSubscription))
                    currentPatientSubscription.unsubscribe();
            }
        }

        function onVisitReorder(order) {
            vm.visitQuery = _.assign(vm.visitQuery, {order: order});
            getVisitData();
        }

        function onVisitPaginate(page, limit) {
            vm.visitQuery = _.assign(vm.visitQuery, {page: page, limit: limit});
            getVisitData();
        }

        function getVisitData() {
            vm.visitPromise = paymentService
                .getVisits(_.assign(vm.visitQuery, {patient: currentPatient}))
                .then(done);

            function done(data) {
                vm.selectedVisit = null;
                vm.visitTotal = data.length;
                vm.visits = data.list;
                vm.visitSummary = _.assign(vm.visitSummary, {
                    gross_total: data.gross_total,
                    paid_amount: data.paid_amount,
                    global_discount: data.global_discount,
                    payment_status: data.payment_status,
                    remaining_amount: data.remaining_amount,
                    organization_affected_amount: data.organization_affected_amount
                });

                if (vm.visitTotal < ((vm.visitQuery.page - 1) * vm.visitQuery.limit)) {
                    vm.visitQuery.page = 1;
                }
            }
        }

        function onEncasementReorder(order) {
            vm.encasementQuery = _.assign(vm.encasementQuery, {order: order});
            getEncasementData();
        }

        function onEncasementPaginate(page, limit) {
            vm.encasementQuery = _.assign(vm.encasementQuery, {page: page, limit: limit});
            getEncasementData();
        }

        function getEncasementData() {
            vm.encasementPromise = paymentService
                .getEncasements(_.assign(vm.encasementQuery, {patient: currentPatient}))
                .then(success);

            function success(data) {
                vm.selectedEncasement = null;
                vm.encasementTotal = data.length;
                vm.encasements = data.list;
                vm.encasementSummary = _.assign(vm.encasementSummary, {
                    total_amount: data.total_amount,
                    consumed_amount: data.consumed_amount,
                    remaining_amount: data.remaining_amount
                });

                if (vm.encasementTotal < ((vm.encasementQuery.page - 1) * vm.encasementQuery.limit)) {
                    vm.encasementQuery.page = 1;
                }


                vm.accountSubject.next(currentPatient);
            }
        }

        function onDentalReorder(order) {
            vm.dentalQuery = _.assign(vm.dentalQuery, {order: order});
            getDentalData();
        }

        function onDentalPaginate(page, limit) {
            vm.dentalQuery = _.assign(vm.dentalQuery, {page: page, limit: limit});
            getDentalData();
        }

        function getDentalData() {
            vm.dentalPromise = paymentService
                .getDentalTreatmentPlan(_.assign(vm.dentalQuery, {patient: currentPatient}))
                .then(done);

            function done(data) {
                vm.selectedDental = null;
                vm.dentalTotal = data.length;
                vm.dental_plans = data.list;
                vm.dentalSummary = _.assign(vm.dentalSummary, {
                    gross_total: data.gross_total,
                    paid_amount: data.paid_amount,
                    global_discount: data.global_discount,
                    payment_status: data.payment_status,
                    remaining_amount: data.remaining_amount,
                    organization_affected_amount: data.organization_affected_amount
                });

                if (vm.dentalTotal < ((vm.dentalQuery.page - 1) * vm.dentalQuery.limit)) {
                    vm.dentalQuery.page = 1;
                }
            }

        }

        function payVisit($event, visit) {
            paymentService.payVisit($event, visit)
                .then(_.mnDelay(getVisitData, 500), _.mnDelay(getVisitData, 500));
        }

        function closeVisit($event, visit) {
            paymentService.closeVisit($event, visit)
                .then(_.mnDelay(getVisitData, 500), _.mnDelay(getVisitData, 500));
        }

        function reopenVisit($event, visit) {
            paymentService.reopenVisit($event, visit)
                .then(_.mnDelay(getVisitData, 500), _.mnDelay(getVisitData, 500));
        }

        function showProcedures(visit) {
            if (vm.selectedVisit == visit.id) {
                vm.selectedVisit = -1;
            } else {
                vm.selectedVisit = visit.id;
            }
        }

        function payDentalTreatmentPlan($event, plan) {
            paymentService.payDentalTreatmentPlan($event, plan)
                .then(_.mnDelay(getDentalData, 500), _.mnDelay(getDentalData, 500));
        }

        function closeDentalTreatmentPlan($event, plan) {
            paymentService.closeDentalTreatmentPlan($event, plan)
                .then(_.mnDelay(getDentalData, 500), _.mnDelay(getDentalData, 500));
        }

        function reopenDentalTreatmentPlan($event, plan) {
            paymentService.reopenDentalTreatmentPlan($event, plan)
                .then(_.mnDelay(getDentalData, 500), _.mnDelay(getDentalData, 500));
        }

        function showTeethProcedures(plan) {
            if (vm.selectedDental == plan.id) {
                vm.selectedDental = -1;
            } else {
                vm.selectedDental = plan.id;
            }
        }

        function showEncasementDetails(encasement) {
            if (vm.selectedEncasement == encasement.id) {
                vm.selectedEncasement = -1;
            } else {
                vm.selectedEncasement = encasement.id;
            }
        }

        function filtersChange() {
            if (vm.visitQuery.hide_paid) vm.visitQuery.hide_no_amount = true;
            getVisitData();
        }

        function goBack() {
            switch (vm.origin) {
                case 'payment':
                    $state.go("app.payment.main", {});

                    break;
                default:
                    $state.go("app.patient-form", {
                        'patient_id': vm.patient.id
                    });
            }
            return
        }
    }
})();
