(function () {

    'use strict';

    module.exports = TextPrescriptionFormCtrl;

    TextPrescriptionFormCtrl.$inject = ["system", "prescriptionService", "$transition$", "$q", "$mdDialog", "$scope", "textEditorService"];

    function TextPrescriptionFormCtrl(system, service, $transition$, $q, $mdDialog, $scope, textEditorService) {
        let vm = this;

        let dateFormat = system['date_format'].js;

        let currentPatient = _.get($transition$.params('to'), 'pId');
        let currentVisit = _.get($transition$.params('to'), 'visitId');
        let currentPrescription = _.get($transition$.params('to'), 'prescriptionId');
        let currentCopy = _.get($transition$.params('to'), 'copy');

        vm.$onInit = init;

        vm.saveModel = saveModel;
        vm.savePrescription = saveTextPrescription;

        vm.deleteItem = service.deleteItem;
        vm.prePrint = prePrint;

        vm.cancel = goBack;

        function init() {
            service.prescriptionType.next({type: 'TextPrescription'});

            service.itemActive.next({id: -1, type: null});
            service.itemDuplicated.next({id: -1, type: null});
            service.itemEdited.next({id: currentPrescription, type: 'TextPrescription'});

            let promise = [];

            if (!_.isNull(currentCopy)) {
                service.itemDuplicated.next({id: currentCopy, type: 'TextPrescription'});
                promise.push(service.getTextPrescription(currentCopy))
            }

            else if (!_.isNull(currentPrescription)) promise.push(service.getTextPrescription(currentPrescription))
            else promise.push(createEmptyTextPrescription());

            vm.promise = $q.all(promise).then(success);

            vm.editorName = _.uniqueId('text-prescription-');
            vm.editorConfigKey = "text_prescription";
            vm.currentPatient = currentPatient;

            vm.editorOpts = {
                patient: currentPatient
            }

            function success(data) {
                let actualData = _.head(data)
                vm.prescription = _.isNull(currentCopy) ? actualData : _.chain(actualData).pick('content').assign({
                    visit: {id: currentVisit},
                    prescription_date: moment().format(dateFormat)
                }).value()

                if (_.isNull(currentCopy)) {
                    vm.editModel = actualData['is_model'];

                    service.prescriptionType.next({
                        type: 'TextPrescription',
                        model: vm.editModel
                    })
                }
            }

            let itemDeletedSubscription = service.itemDeleted.subscribe(itemDeleted);
            let itemAddedSubscription = service.addTextPrescriptionSubject.subscribe(itemAdded);

            function itemDeleted(item) {
                if (_.isEqual(item, {id: currentPrescription, prescription_type: 'TextPrescription'})) {
                    service.listState();
                }
            }

            function itemAdded(id) {
                vm.promise = service.getTextPrescription(id)
                    .then(prescription => {
                        vm.prescription.content = `${vm.prescription.content}\n${prescription.content}`;
                    });
            }

            $scope.$on("$destroy", onDestroy);

            function onDestroy() {
                itemDeletedSubscription.unsubscribe();
                itemAddedSubscription.unsubscribe();
            }
        }

        function createEmptyTextPrescription() {
            return {
                content: "",
                visit: {id: currentVisit},
                prescription_date: moment().format(dateFormat)
            }
        }

        function saveTextPrescription(quit) {
            return service.saveTextPrescription(vm.prescription, currentPatient)
                .then(success)

            function success(data) {
                if (vm.editModel) service.modelAdded.next(data);
                else service.itemAdded.next(data);

                if (quit === "list") return service.listState();
                if (quit === "back") return goBack();

                if (_.isNull(currentPrescription)) {
                    vm.prescription = data;
                    currentPrescription = data.id;
                    service.refreshState(data);
                }
            }
        }

        function saveModel($event) {
            let confirm = $mdDialog.mnPromptDialog()
                .title('exam_model_title')
                .event('prescription.TextPrescription.title_validation')
                .placeholder('title')
                .targetEvent($event)

            $mdDialog.show(confirm).then(success);

            function success(data) {
                // TODO need to import the model when the editor is fully fixed
                let model = _.assign(_.pick(vm.prescription, 'content'), {title: data});

                service.saveTextPrescription(model)
                    .then(success);

                function success(data) {
                    service.modelAdded.next(data);
                }
            }
        }

        function prePrint() {
            let deferred = $q.defer();

            textEditorService
                .getEditor(vm.editorName)
                .compileContent();


            saveTextPrescription()
                .then(function () {
                    deferred.resolve(false);
                });


            return deferred.promise;
        }

        function goBack() {
            return window.history.back();
        }

    }

})();