/**
 * Created by BETALOS on 01/03/2017.
 */
(function () {

    'use strict';

    class AnimateHeight {
        constructor(storageService) {
            this.restrict = "A";
            this.storageService = storageService;
            this.scope = {
                content: '@mnAnimateHeight', forceOpen: "<"
            }
        }

        static create() {
            return new AnimateHeight(...arguments);
        }

        link(scope, element, attrs) {
            let changes = null;
            let $parent = element.parent();
            let $content = $(scope.content, $parent);
            let namespace = _.get(attrs, 'namespace');
            let resetHeight = () => {
                $content.css({height: $content.height() > 0 ? 'auto' : 0});
            }

            $content.on('transitionend', resetHeight);
            $content.css({transition: 'height 400ms cubic-bezier(0.35, 0, 0.25, 1)', overflow: 'hidden'});

            if (element.is('.md-button')) element.on('click', () => this.handleToggle(element, $content, $parent, namespace));
            else element.on('click', 'button.md-button', () => this.handleToggle(element, $content, $parent, namespace));

            if (element.is("[force-open]")) {
                scope.$watch('forceOpen', value => {
                    if (value) this.open(element, $content, $parent);
                    else this.getLastState(element, namespace).then(
                        value => this.handleInitialLoad(value, element, $content, $parent)
                    );
                });
            } else this.getLastState(element, namespace).then(
                value => this.handleInitialLoad(value, element, $content, $parent)
            );

            if (window.MutationObserver) {
                changes = new window.MutationObserver(() => {
                    if ($parent.is('.block-opened')) setTimeout(() => this.open(element, $content, $parent), 100);
                });

                changes.observe($content.get(0), {childList: true, subtree: true, attributes: false});
            }

            scope.$on("$destroy", () => {
                if (changes) changes.disconnect();
            });
        }

        handleInitialLoad(value, element, $content, $parent) {
            if (value == 'OPENED') this.open(element, $content, $parent);
            else this.close(element, $content, $parent);
        }

        handleToggle(element, content, parent, namespace) {
            let isContentVisible = parent.is('.block-opened');

            if (isContentVisible) this.close(element, content, parent);
            else this.open(element, content, parent);

            if (namespace) this.toggleState(namespace, !isContentVisible);
        }

        open(element, content, parent) {
            content.show();

            const curHeight = content.css('height');
            const height = content.css({height: 'auto'}).outerHeight(true);

            if (height == 0) setTimeout(() => this.open(element, content, parent), 100);
            else {
                content.css('height', curHeight);

                requestAnimationFrame(() => {
                    content.css({height});
                    $('md-icon', element).addClass('rotation');
                    parent.removeClass('block-closed').addClass('block-opened');
                });
            }
        }

        close(element, content, parent) {
            if (parent.is('.block-closed')) return;

            const curHeight = content.css('height');
            content.css('height', curHeight);

            requestAnimationFrame(() => {
                content.css({height: 0});
                $('md-icon', element).removeClass('rotation');
                parent.removeClass('block-opened').addClass('block-closed');
            });
        }

        getLastState(element, namespace) {
            if (namespace) return this.storageService.getKey(namespace);
            else return Promise.resolve(element.is('[opened]') ? 'OPENED' : 'CLOSED');
        }

        toggleState(namespace, state) {
            this.storageService.setKey(namespace, state ? 'OPENED' : 'CLOSED');
        }
    }


    AnimateHeight.create.$inject = ["storageService"];

    module.exports = AnimateHeight.create;

})();