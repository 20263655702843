/**
 * Created by amine on 16/02/2017.
 */
(function () {
    'use strict';

    module.exports = MainPaymentContainerCtrl;

    MainPaymentContainerCtrl.$inject = ["$translate", "paymentService", "$mdDialog", "$state"];

    function MainPaymentContainerCtrl($translate, paymentService, $mdDialog, $state) {
        let vm = this;

        vm.patient = null;
        vm.paginationLabel = {
            page: $translate.instant('page'),
            rowsPerPage: $translate.instant('rowsPerPage'),
            of: $translate.instant('of')
        };

        vm.options = [20, 15, 10, 5];

        vm.selectedEncasement = null;
        vm.encasementPromise = null;
        vm.encasements = [];
        vm.encasementTotal = 0;
        vm.encasementQuery = {
            limit: 10,
            page: 1,
            order: "-encasement_date",
            search_all: "",
            start_date: null,
            end_date: null,
            payment_mode: null,
            physician: null
        };
        vm.encasementSummary = {
            total_amount: 0,
            consumed_amount: 0,
            remaining_amount: 0
        };

        vm.selectedAccount = null;
        vm.accountPromise = null;
        vm.accounts = [];
        vm.accountTotal = 0;
        vm.accountBalance = 0;

        vm.accountQuery = {
            limit: 10,
            page: 1,
            order: "name",
            only_neg: true,
            account_type: "P"
        };

        vm.balance = 0;

        vm.$onInit = init;

        vm.newEncasement = newEncasement;
        vm.deleteEncasement = deleteEncasement;
        vm.resetEncasement = resetEncasement;

        vm.onEncasementReorder = onEncasementReorder;
        vm.onEncasementPaginate = onEncasementPaginate;
        vm.getEncasementData = getEncasementData;
        vm.showEncasementDetails = showEncasementDetails;

        vm.onAccountReorder = onAccountReorder;
        vm.onAccountPaginate = onAccountPaginate;
        vm.getAccountData = getAccountData;
        vm.showAccountDetails = showAccountDetails;
        vm.loadGeneralAccountData = loadGeneralAccountData;

        function init() {
            vm.mainModule = true;
            getEncasementData();
        }

        function newEncasement() {
            paymentService['encasementState']();
        }

        function resetEncasement(encasement, ev) {
            const confirm = $mdDialog.confirm()
                .title($translate['instant']("encasement_edit_confirm_title"))
                .textContent($translate['instant']("encasement_remove_confirm_text"))
                .ariaLabel('edit plan confirm')
                .targetEvent(ev)
                .ok($translate['instant']('confirm_ok'))
                .cancel($translate['instant']('confirm_cancel'));

            $mdDialog.show(confirm).then(reset, _.noop);

            function reset() {
                $state.go("app.payment.form", {
                    encasementId: encasement.id,
                    reset: true,
                }, {inherit: true});
            }
        }

        function deleteEncasement(encasement, ev) {
            paymentService.deleteEncasement(encasement, ev)
                .then(success, _.noop);

            function success() {
                getEncasementData();
            }
        }

        function onEncasementReorder(order) {
            vm.encasementQuery = _.assign(vm.encasementQuery, {order: order});
            getEncasementData();
        }

        function onEncasementPaginate(page, limit) {
            vm.encasementQuery = _.assign(vm.encasementQuery, {page: page, limit: limit});
            getEncasementData();
        }

        function getEncasementData() {
            vm.encasementPromise = paymentService
                .getEncasements(vm.encasementQuery)
                .then(done, _.noop);

            function done(data) {
                vm.selectedEncasement = null;
                vm.encasementTotal = data.length;
                vm.encasements = data.list;
                vm.encasementSummary = _.assign(vm.encasementSummary, {
                    total_amount: data.total_amount,
                    consumed_amount: data.consumed_amount,
                    remaining_amount: data.remaining_amount
                });

                if (vm.encasementTotal < ((vm.encasementQuery.page - 1) * vm.encasementQuery.limit))
                    vm.encasementQuery.page = 1;
            }
        }

        function showEncasementDetails(encasement) {
            if (vm.selectedEncasement === encasement.id) {
                vm.selectedEncasement = -1;
            } else {
                vm.selectedEncasement = encasement.id;
            }
        }

        function onAccountReorder(order) {
            vm.accountQuery = _.assign(vm.accountQuery, {order: order});
            getAccountData();
        }

        function onAccountPaginate(page, limit) {
            vm.accountQuery = _.assign(vm.accountQuery, {page: page, limit: limit});
            getAccountData();
        }

        function getAccountData() {
            vm.accountPromise = paymentService
                .getGeneralAccount(vm.accountQuery)
                .then(done, _.noop);

            function done(data) {
                vm.selectedEncasement = null;
                vm.accountTotal = data.length;
                vm.accountBalance = data.balance.value;
                vm.accounts = data.list;

                if (vm.accountTotal < ((vm.accountQuery.page - 1) * vm.accountQuery.limit))
                    vm.accountQuery.page = 1;
            }
        }

        function showAccountDetails(account) {
            if (vm.selectedAccount == account.id) {
                vm.selectedAccount = -1;
            } else {
                vm.selectedAccount = account.id;
            }
        }

        function loadGeneralAccountData() {
            if (_.isNil(vm.accountPromise)) getAccountData();
        }
    }
})();